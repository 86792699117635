$(document).on('ready', () => {
  if ($('#purchases-payment').length !== 0 || $('#purchases-payment-processing').length !== 0) {
    SlickSlider('#event_suggestions', { to_show: 4 })

    $('.payment-card__radio_input').on('change', function () {
      var mbway = document.getElementById('purchases_payment_form_payment_method_mbway')
      var paypal = document.getElementById('purchases_payment_form_payment_method_paypal')
      var mb = document.getElementById('purchases_payment_form_payment_method_mb')

      $('#payment_method_error').css('display', 'none')

      if (mbway) {
        if (mbway.checked) {
          $('#mbway_phone_input').css('display', 'block')
        } else {
          $('#mbway_phone_input').css('display', 'none')
        }
      }

      if (paypal) {
        if (paypal.checked) {
          $('#paypal_input').css('display', 'block')
        } else {
          $('#paypal_input').css('display', 'none')
        }
      }

      if (mb) {
        if (mb.checked) {
          $('#mb_input').css('display', 'block')
        } else {
          $('#mb_input').css('display', 'none')
        }
      }
    })
  }
})
