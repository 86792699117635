import Base from '../../../base'

class EventSessions extends Base {
  setup () {
    configCheckboxes()

    document.addEventListener('sessions-loaded', function (ev) {
      configCheckboxes()
    })

    // Setup toggler and sessions box (new sessions)
    $('#toggler').on('click', function () {
      if ($('#show_more').hasClass('fa-plus')) {
        $('#partial').css('display', 'block')
        $('#show_more').addClass('fa-minus')
        $('#show_more').removeClass('fa-plus')
      } else {
        $('#partial').css('display', 'none')
        $('#show_more').addClass('fa-plus')
        $('#show_more').removeClass('fa-minus')
      }
    })

    $('#all_sessions').on('click', () => {
      $('#all_sessions_helper').val('true')
    })
    $('#clear_sessions').on('click', clearAllSessions)

    /* HELPERS */
    function clearAllSessions () {
      if ($('#calendar_input').get(0)) {
        $('#calendar_input').trigger('click')
      } else {
        $('.tl_control_checkbox  > input').each(function () {
          $(this).prop('checked', false)
        })
        $('.pika-table td').each(function () {
          if (!$(this).hasClass('is-disabled')) {
            $(this).removeClass('has-event')
          }
        })
      }
      $('#apply').get(0).disabled = true
      $('#apply').get(0).value = 'Aplicar'
    }

    function selectAllVisibleSessions (ev) {
      $('.organizer_session__wrapper').each(function () {
        if (this.style.display == 'block') {
          const items = this.getElementsByClassName('session_checkbox')
          for (const item of items) {
            item.checked = true
          }
        }
      })
    }

    function clearAllVisibleSessions () {
      $('.organizer_session__wrapper').each(function () {
        if (this.style.display == 'block') {
          const items = this.getElementsByClassName('session_checkbox')
          for (const item of items) {
            item.checked = false
          }
        }
      })
    }

    function configCheckboxes () {
      const selectedEventSessionIds = $('#sessions_box').data('event_session_ids') || []
      if (selectedEventSessionIds.length !== 0) {
        const filteredSessions = selectedEventSessionIds

        if (filteredSessions.length !== 0) {
        // Check checkboxes from the selected sessions
          filteredSessions.forEach(function (eventSessionId) {
            $('#event_session_' + eventSessionId).prop('checked', true)
          })
        }

        // check if for this day are all selected and select select all
        let allSelected = true
        $('.organizer_session__wrapper').each(function () {
          if (this.style.display === 'block') {
            const items = this.getElementsByClassName('session_checkbox')
            for (const item of items) {
              if (!item.checked) {
                allSelected = false
              }
            }
          }
        })
        $('#all_visible_sessions').get(0).checked = allSelected
        $('#apply').get(0).value = `Aplicar (${selectedEventSessionIds.length})`
        if (selectedEventSessionIds.length !== 0) {
          $('#apply').get(0).disabled = false
        }
      }
      // enable apply button when some checkbox is selected
      $(':checkbox').each(function () {
        $(this).on('change', function () {
          let totalSelected = 0
          $('.session_checkbox').each(function () {
            if (this.checked === true) {
              totalSelected += 1
            }
          })

          const applyButton = $('#apply').get(0)
          if (applyButton) {
            if (totalSelected !== 0) {
              applyButton.disabled = false
            } else {
              applyButton.disabled = true
            }

            applyButton.value = `Aplicar (${totalSelected})`
          }
        })
      })

      // select all checkbox onclick behaviour
      $('.all_visible_sessions').each(function () {
        $(this).on('click', function () {
          if ($(this).is(':checked')) {
            selectAllVisibleSessions()
          } else {
            clearAllVisibleSessions()
          }
        })
      })
    }
  }
}

export default EventSessions
