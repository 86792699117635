import { Controller } from 'stimulus'
import Cookies from 'js-cookie'

export default class extends Controller {
  connect () {
    moment.locale(Cookies.get('locale'))

    $(this.element).datetimepicker({
      useCurrent: false,
      sideBySide: true,
      locale: Cookies.get('locale'),
      format: this.format,
      icons: {
        up: 'fa fa-chevron-up',
        down: 'fa fa-chevron-down',
        next: 'fa fa-chevron-right',
        previous: 'fa fa-chevron-left',
        clear: 'far fa-calendar-times'
      },
      showClear: this.showClear
    })
  }

  get format () {
    return this.element.dataset.format || 'DD/MM/YYYY'
  }

  get showClear () {
    return this.element.dataset.showClear === 'true'
  }
}
