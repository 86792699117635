/* global $ */
import objectDeepMerge from 'deepmerge'
import { Controller } from 'stimulus'
import { DistrictsMap, defaultOptions } from 'portugal-maps'

export default class extends Controller {
  connect () {
    $(document).ready(this.create())
  }

  create () {
    const $this = $(this.element)

    const containerId = 'portugal-map-container'
    const mapContainer = jQuery('<div/>', {
      id: containerId
    })
    $this.append(mapContainer)

    this.districtsMap = new DistrictsMap({
      selectionEnabled: false,
      tooltipEnabled: true,
      districtOptions: this.districtTooltipOptions,
      height: '500px',
      stroke: {
        color: '#fff'
      },
      islandBorderStroke: {
        color: '#D5D5D6',
      },
      unselected: {
        color: '#edecec',
        hover: {
          color: '#edecec'
        }
      }
    })

    this.districtsMap.render(`#${containerId}`)

    // Setup hover hover of legend when exists
    this.setupHoverOnLegend()
  }

  get districtData () {
    return JSON.parse(this.data.get('district-data')) || {}
  }

  showTooltip (districtId) {
    // This can be the districtId (to show the tooltip on the district) or null (to hide the tooltip)
    this.districtsMap.showTooltipDistrict(districtId, false)
  }

  get districtTooltipOptions () {
    // Get the district codes by Name
    const districtNames = defaultOptions.districtNames
    const districtCodesByName = Object.fromEntries(Object.entries(districtNames).map(a => a.reverse()))

    // Convert chart data to tooltip options
    const chartData = this.districtData
    return Object.keys(districtCodesByName).reduce((res, districtName) => {
      const districtCode = districtCodesByName[districtName]
      res[districtCode] = this.formattedDistrictOptions(chartData[`PT.${districtCode}`] || {})
      return res
    }, {})
  }

  formattedDistrictOptions (districtData) {
    const tooltipData = districtData?.tooltip || []
    let options = {
      tooltip: {
        body: `<div>
                ${tooltipData[1] || 0} bilhetes vendidos<br>\
                ${tooltipData[2] || 0} €<br>
                ${tooltipData[3] || 0} %
               </div>`
      }
    }

    if (districtData.percentage > 0) {
      const districtColor = this.getDistrictColor(districtData.percentage)
      options = objectDeepMerge(options, {
        unselected: {
          color: districtColor,
          hover: {
            color: districtColor
          }
        }
      })
    }

    return options
  }

  getDistrictColor (percentage) {
    percentage = percentage || 0
    const GREEN_HUE = 75
    const GREEN_SAT = 70
    const DARKER_LIGHT = 15
    const LIGHTER_LIGHT = 85

    const newLightness = LIGHTER_LIGHT - percentage * (LIGHTER_LIGHT - DARKER_LIGHT)

    return hsl2hex(GREEN_HUE / 360, GREEN_SAT / 100, newLightness / 100)
  }

  setupHoverOnLegend () {
    // Setup hover for legend
    const $legendRows = $('#sales_per_local_card_legend').find('.legend')

    if ($legendRows === undefined) return

    $legendRows.each((_index, rowEl) => {
      const localId = $(rowEl).find('.legend__resume').attr('data-local-id')

      // If it is not a district (PT.BE, etc.), there is no hover
      if (!localId.startsWith('PT.')) return

      // Convert from our local ids to the map ids, for example: PT.BE -> BE
      const districtId = localId.replace('PT.', '')

      $(rowEl).on('mouseover', () => this.showTooltip(districtId, true))
      $(rowEl).on('mouseout', () => this.showTooltip(null, true))
    })
  }
}
