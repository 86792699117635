import i18n from './i18n'

// Reference for withLocale: https://fnando.github.io/i18n/v4.0.2/classes/i18n.i18n-1.html#withlocale
i18n.withLocale('en', () => {
  moment.updateLocale('en', {
    monthsShort: i18n.t('date.abbr_month_names').slice(1),
    weekdaysShort: i18n.t('date.abbr_day_names')
  })
})

i18n.withLocale('es', () => {
  moment.updateLocale('es', {
    monthsShort: i18n.t('date.abbr_month_names').slice(1),
    weekdaysShort: i18n.t('date.abbr_day_names')
  })
})

i18n.withLocale('pt', () => {
  moment.updateLocale('pt', {
    monthsShort: i18n.t('date.abbr_month_names').slice(1),
    weekdaysShort: i18n.t('date.abbr_day_names')
  })
})
