$(document).on('ready', () => {
  if ($('#admin-event_lists-show').length !== 0) {
    const $eventSelect = $('#event_select').first()
    const $items = $("[selector='items']").first()

    Sortable.create($items.get(0))
    addListnerToDelete()

    $eventSelect.on('change', function ($ev) {
      const selectEl = $ev.target
      const $select = $(selectEl)
      const eventId = $select.val()
      const item = JSON.stringify({ type: 'event', id: eventId })

      if (eventId == null) return

      if ($("[event_id='" + eventId + "']").length == 0) {
        $items.append("\
          <div class='event_show' event_id='" + eventId + "'>\
            <div class='mx-2 align-self-center event_show__item'>\
              <div class='event_show__item--icon'><i class='fas fa-sort'></i></div>\
              " + selectEl.selectedOptions[0].innerText + "\
            </div>\
              <div class='mx-2 align-self-center text-right'>\
                <a href='/events/" + eventId + "', target='_blank', class='btn btn-light' do %>\
                    <span class='fal fa-eye fa-fw'>\
                </a>\
                <div class='btn btn-danger delete_button' >\
                  <span class='fal fa-trash-alt fa-fw'>\
                </div>\
              </div>\
              <input multiple='multiple' type='hidden' name='event_list[items][]' value='" + item + "'>\
            </div>\
          </div>\
        ")

        $('#events_container > p').hide()
      }
      $('#events_container').val(null).trigger('change')
      addListnerToDelete()
    })
  }
})

function addListnerToDelete () {
  document.querySelectorAll('.delete_button').forEach(item => {
    item.addEventListener('click', event => {
      removeEvent(event)
    })
  })
}

function removeEvent (ev) {
  const $icon = $(ev.target)
  const $newEvent = $icon.closest('[event_id]')
  $newEvent.remove()
}
