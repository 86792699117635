import i18n from 'packs/initializers/i18n'

$(document).on('ready', () => {
  if ($('#events-show').length !== 0) {
    // Scroll to location dropdowns when banner buy button is clicked
    $('.scroll-buy').on('click', function () {
      $('html, body').animate({ scrollTop: $('#section-buy').offset().top - 100 }, 1500)
    })

    // Event suggestions slider
    new SlickSlider('#event_suggestions', { to_show: 4 })

    /* SYNOPSIS */
    if (document.getElementById('synopsis')) {
      // Hide extra synopsis content if its height is bigger than 300 px
      const synopsisTextEl = document.getElementById('synopsis-text')
      const synopsisContainerEl = document.getElementById('synopsis-container')
      const backgroundShadowEl = document.getElementById('shadow-bg')
      const toggleEL = document.querySelector("[selector='synopsis_toggle']")

      if (synopsisTextEl.offsetHeight < 300) {
        backgroundShadowEl.classList.remove('synopsis__bg')
        toggleEL.parentNode.removeChild(toggleEL)
      }

      // Toggle extra synopsis content visibility
      let show = false
      $(toggleEL).on('click', function () {
        show = !show

        if (show) {
          synopsisContainerEl.style.maxHeight = 'max-content'
          backgroundShadowEl.classList.remove('synopsis__bg')
          toggleEL.innerHTML = `<i class="far fa-arrow-up"></i> ${i18n.t('views.event.viewless')}`
        } else {
          synopsisContainerEl.style.maxHeight = '300px'
          backgroundShadowEl.classList.add('synopsis__bg')
          toggleEL.innerHTML = `<i class="far fa-arrow-down"></i> ${i18n.t('views.event.viewmore')}`
        }
      })
    }

    /* MULTIMEDIA */
    if (document.getElementById('multimedia')) {
      $('.multimedia__card').on('click', ev => selectImage(ev.target))

      document.querySelectorAll('[data-type="vimeo"]').forEach(item => {
        $.get(`https://vimeo.com/api/v2/video/${item.dataset.srcId}.json`)
          .then(data => (item.style.backgroundImage = `url('${data[0].thumbnail_small}')`))
      })
    }
  }

  function selectImage (imgEl) {
    const expandImgEl = document.getElementById('container-multimedia')
    const divEl = document.createElement('div')
    divEl.className = 'multimedia__inner'
    expandImgEl.innerHTML = ''
    expandImgEl.appendChild(divEl)

    if (imgEl.dataset.type === 'youtube') {
      divEl.innerHTML = `<div class="video" data-plyr-provider="youtube" data-plyr-embed-id="${imgEl.dataset.srcId}"></div>`
      new Plyr(divEl.firstElementChild, { autoplay: false, muted: true, controls: ['play', 'mute'] })
    } else if (imgEl.dataset.type === 'vimeo') {
      divEl.innerHTML = `<div class="video" data-plyr-provider="vimeo" data-plyr-embed-id="${imgEl.dataset.srcId}"></div>`
      new Plyr(divEl.firstElementChild, { autoplay: false, muted: true, controls: ['play', 'mute'] })
    } else {
      divEl.innerHTML = `<img src="${imgEl.dataset.srcId}" />`
    }
  }
})
