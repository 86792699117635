export default function setupSelect (element, currDocument = document, onchange = (value) => {}) {
  const $this = $(element)

  if ($this.parent().hasClass('select_container')) return

  // Cache the number of options
  const numberOfOptions = $this.children('option').length

  // Hides the select element
  $this.addClass('s-hidden')

  // Wrap the select element in a div
  $this.wrap('<div class="select_container"></div>')

  // Insert a styled div to sit over the top of the hidden select element
  $this.after('<div class="styled_select"></div>')

  // Cache the styled div
  const $styledSelect = $this.next('div.styled_select')

  // Show the first select option in the styled div
  $styledSelect.text($this.children('option:selected').text())

  // Insert an unordered list after the styled div and also cache the list
  let $list = null

  if (
    $this.parent().parent().hasClass('select--manager') ||
    $this.parent().parent().parent().hasClass('select--manager')
  ) {
    $list = $('<ul />', { class: 'options options--manager ' }).insertAfter(
      $styledSelect
    )
  } else {
    $list = $('<ul />', { class: 'options ' }).insertAfter($styledSelect)
  }

  // Insert a list item into the unordered list for each select option
  for (let i = 0; i < numberOfOptions; i++) {
    $('<li />', {
      text: $this.children('option').eq(i).text(),
      rel: $this.children('option').eq(i).val()
    }).appendTo($list)
  }

  // Cache the list items
  const $listItems = $list.children('li')

  $styledSelect.click(function (e) {
    e.stopPropagation()
    for (const e of currDocument.querySelectorAll('div.styled_select.active')) {
      $(e).removeClass('active').next('ul.options').hide()
    }
    if ($(e.target).hasClass('active')) {
      $(e.target).removeClass('active').next('ul.options').hide()
    } else {
      $('div.styled_select').removeClass('active').next('ul.options').hide()
      $(e.target).addClass('active').next('ul.options').show()
    }
  })

  // Hides the unordered list when a list item is clicked and updates the styled div to show the selected list item
  // Updates the select element to have the value of the equivalent option
  $listItems.click(function (e) {
    e.stopPropagation()
    $styledSelect.text($(e.target).text()).removeClass('active')
    $this.val($(e.target).attr('rel')).trigger('change')
    onchange($(e.target).attr('rel'))
    $list.hide()
    // alert($this.val()) // Uncomment this for demonstration!
  })

  // Check if text is too big
  const maxLength = 14
  if ($styledSelect.prevObject[0].className.includes('stripped-text')) {
    $styledSelect.text(function (i, text) {
      if (text.length > maxLength) {
        return text.substr(0, maxLength) + '...'
      }
    })
  }

  // Hides the unordered list when clicking outside of it
  $(document, currDocument).click(function () {
    $styledSelect.removeClass('active')
    $list.hide()
  })
}
