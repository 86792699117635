import { I18n } from 'i18n-js'
import translations from './translations.json'

const i18n = new I18n(translations)

$(document).on('ready', () => {
  i18n.locale = $('body').data('locale')
})

export default i18n
