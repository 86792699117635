import { Controller } from 'stimulus'

export default class extends Controller {
    connect () {
        // Setup financial promotor select
        $("[select2-target='financial_reports_promotor_tl_id']").select2({width: null})

        // On promotor select change
        $("[select2-target='financial_reports_promotor_tl_id']").on('change' , () => {
            $('#event_id').val(null).trigger('change')
            $("#financial_report").submit()
            $('.full-spinner').css('display', 'block')
        })

        // On open make the request
        $("#event_id").on('select2:open', () => {
            const $start_date = $('#financial_start_date')
            const $end_date = $('#financial_end_date')
            const $promotor_tl_id = $("[select2-target='financial_reports_promotor_tl_id']")
            const $year = $('#saft_year')

            document.querySelector("#event_id").dataset.ajaxData = JSON.stringify({
                include_blank: "Todos",
                leaf_events: true,
                op_filter_start_date: $start_date.val(),
                op_filter_end_date: $end_date.val(),
                promotor_tl_id: $promotor_tl_id.val(),
                saft_year: $year.val(),
                limit: 0
            })
        })

        // On selection
        $('#event_id').on('select2:select', (e) => {
            const option = e.params.data.id
            // HACK: Change value from select2
            $('#event_id').val(option).trigger('change')
        })
    }

    download (event) {
        event.preventDefault()

        const $anchor = $(event.currentTarget) 
        const $form = $anchor.closest("form")

        Pages.Manager.Downloads.onclick(event)

        $form.attr("action", $anchor.attr("href"))
        $form.submit()
    }

}
