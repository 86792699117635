$(document).on('ready', () => {
  if ($('#managers-management-edit-manager').length !== 0) {
    // Setup resend invite in case of name/email change
    const initialDataAndId = {
      name: [$('#manager_name').val(), '#manager_name'],
      email: [$('#manager_email').val(), '#manager_email']
    }
    const resendInviteButton = $('[selector="save-reinvite-button"]')
    const buttonToggler = (id) => {
      const data = initialDataAndId[id]
      if (data[0] === $(data[1]).val()) resendInviteButton.prop('disabled', true)
      else resendInviteButton.prop('disabled', false)
    }
    $('#manager_name').on('input', () => buttonToggler('name'))
    $('#manager_email').on('input', () => buttonToggler('email'))

    // Setup resend invite button behavior
    resendInviteButton.on('click', () => {
      $('#manager_resend_invite').val(true)
    })
  }
})
