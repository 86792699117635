const CREATOR = {
  DEFAULT_SCHEMA: {"type":"object","properties":{"":{"type":"string","field_type":0,"position":0}},"required":[]},
  FIELD_TYPE_TEXT: 0,
  FIELD_TYPE_SELECT: 1,
  FIELD_TYPE_SINGLE_CHECKBOX: 2,
  FIELD_TYPE_MULTIPLE_CHECKBOX: 3,
  FIELD_TYPE_DATE: 4,
  FIELD_TYPE_RADIO: 5,
  FIELD_TYPE_INCREMENTABLE: 6,
  FIELD_TYPE_LICENSE_PLATE: 7,
  FIELD_TYPES: {"Campo de texto":0,"Dropdown":1,"Checkbox":2,"Escolha múltipla":3,"Data":4,"Escolha obrigatória":5,"Campo numérico incremental":6,"Campo para matrícula":7}
}

export { CREATOR }