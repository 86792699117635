import * as Routes from 'routes.js.erb'

$(document).on('ready', () => {
  if ($('#general-index').length !== 0) {
    $('.scroll-down').on('click', function () {
      $('html, body').animate({
        scrollTop: $('#homepage_scroll').offset().top - 40
      }, 1500)
    })

    $('[homepage_events_list]').each((_index, target) => SlickSlider(`[homepage_events_list='${target.getAttribute('homepage_events_list')}']`))
    new SlickSlider('#nearby_events', { update_path: Routes.nearby_events_path() })
    new SlickSlider('#museus_events')
    // new SlickSlider('#categories')
  }
})
