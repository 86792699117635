import Base from '../pages/base'

class NonBlockedOccupationLogicService extends Base {
  setup () {
    const blockedOccupationSelector = document.querySelector("[selector='occupation_toggler']")

    if (!blockedOccupationSelector) return

    const showCorrect = (isNonBlockedOccupation) => {
      document.querySelectorAll('[selector="blocked_occupation"]')
        .forEach(el => (el.style.display = !isNonBlockedOccupation ? 'flex' : 'none'))
      document.querySelectorAll('[selector="blocked_occupation_child"]')
        .forEach(el => (el.style.display = !isNonBlockedOccupation ? '' : 'none'))
      document.querySelectorAll('[selector="non_blocked_occupation"]')
        .forEach(el => (el.style.display = isNonBlockedOccupation ? 'flex' : 'none'))
      document.querySelectorAll('[selector="non_blocked_occupation_child"]')
      .forEach(el => (el.style.display = isNonBlockedOccupation ? '' : 'none'))

      const fields = document.querySelectorAll('[selector="non_blocked_occupation_field"]')
      let param = ''
      for (const field of fields) {
        field.value = isNonBlockedOccupation
        param = field.getAttribute('name')
      }

      const totalLabel = document.querySelector('[selector="occupation_blocked_label"]')
      if (totalLabel) totalLabel.style.display = !isNonBlockedOccupation ? 'flex' : 'none'
      const nonBlockedLabel = document.querySelector('[selector="occupation_blocked_label"]')
      if (nonBlockedLabel) nonBlockedLabel.style.display = isNonBlockedOccupation ? 'flex' : 'none'

      const pagination = document.querySelector('[selector="events-search-controller"]')
      if (pagination) {
        const links = pagination.querySelectorAll('a')
        for (const paginator of links) {
          if (paginator.href) {
            const url = new window.URL(paginator.href)
            url.searchParams.set(param, blockedOccupationSelector.checked)
            paginator.href = url.toString()
          }
        }
      }
    }

    const isNonBlockedOccupation = blockedOccupationSelector.checked
    showCorrect(isNonBlockedOccupation)

    blockedOccupationSelector.addEventListener('click', function (ev) {
      const isNonBlockedOccupation = blockedOccupationSelector.checked
      showCorrect(isNonBlockedOccupation)

      // Inform backend if necessary
      const url = `${blockedOccupationSelector.getAttribute('data-url')}?checked=${blockedOccupationSelector.checked}`
      if (url) fetch(url)
    })
  }
}

export default NonBlockedOccupationLogicService
