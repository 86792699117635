$(document).on('ready', () => {
  if ($('#events-event-session').length !== 0) {
    const calculateTotals = () => {
      let totalTickets = 0
      let totalPrice = 0
      const $button = $('.event_session_bottom__submit')

      $('.ticket_count').each(function () {
        const ticketPrice = $(this).parents('.render_typology').find('.ticket_price').text()
        const ticketCount = parseFloat($(this).text())
        totalTickets += ticketCount
        totalPrice += parseFloat(ticketPrice.replace(',', '.')).toFixed(2) * ticketCount
      })

      $('.total_tickets').text(totalTickets)

      $('.total_price').text(totalPrice.toFixed(2).replace('.', ','))

      if (totalTickets >= 1) {
        $button.css({ 'background-color': '#bd161e', 'border-color': '#bd161e' })
        $button.prop('disabled', false)
      } else {
        $button.css({ 'background-color': '#9B9B9B', 'border-color': '#9B9B9B' })
        $button.prop('disabled', true)
      }
    }

    $('.increment').on('click', function () {
      const count = $(this).parent().find('.ticket_count')
      const hiddenCount = $(this).parent().find('.hidden_count')
      const availability = $(this).parent().find('.hidden_availability')
      const $button = $('.event_session_bottom__submit')

      if (availability.val() === 'true') {
        count.text(`${parseInt(count.text()) + 1}`)
        hiddenCount.val(count.text())

        if (count.text() === '1') {
          $(this).parent().find('.decrement').removeClass('bg-light-grey').addClass('bg-dark-grey')
          $button.css({ 'background-color': '#bd161e', 'border-color': '#bd161e' })
          $button.prop('disabled', false)
        }

        calculateTotals()
      }
    })

    $('.decrement').on('click', function () {
      const count = $(this).parent().find('.ticket_count')
      const hiddenCount = $(this).parent().find('.hidden_count')

      if (parseInt(count.text()) > 0) {
        count.text(`${parseInt(count.text()) - 1}`)
        hiddenCount.val(count.text())
        const $button = $('.event_session_bottom__submit')

        if (count.text() === '0') {
          $(this).removeClass('bg-dark-grey').addClass('bg-light-grey')
          $button.css({ 'background-color': '#9B9B9B', 'border-color': '#9B9B9B' })
          $button.prop('disabled', true)
        }

        calculateTotals()
      }
    })
  }
})
