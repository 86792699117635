import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["head", "body", "arrow"]

  connect () {
    if (this.element.dataset.collapsed == "false") this.headTarget.click()
  }

  open () {
    $(this.arrowTarget).toggleClass('down')
    $(this.bodyTarget).slideToggle()
    $(this.headTarget).toggleClass('event_dropdown__container--opened')
  }

}