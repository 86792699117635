$(document).on('ready', () => {
  if ($('#managers-management-index').length !== 0) {
    const managementFilterSubmit = () => {
      $('[selector="loading-spinner"]').css('display', 'block')
      // Hide table
      $('[async-target="full-list"]').css('display', 'none')
      // Hide paginator
      $('[selector="management-list-paginator"]').css('display', 'none')
    }

    // Set up full spinner on form submit
    $('#filters').on('submit', managementFilterSubmit)

    // Set loading spinner for pagination (and prevent full spinner)
    $(document).on('managers-list-loaded', () => {
      $('.paginator .page-item a').on('click', managementFilterSubmit)
    })

    // Set up selects
    const searchButton = $('[manager-search="submit"]')
    $("[select2-target='kind_id']").select2({
      width: null
    }).on('change', (_ev, eventData) => {
      // If event says not to update, do not update
      if (eventData && eventData?.update === false) return

      searchButton.trigger('click')
    })

    $("[select2-target='invite_status']").select2({
      width: null
    }).on('change', (_ev, eventData) => {
      // If event says not to update, do not update
      if (eventData && eventData?.update === false) return

      searchButton.trigger('click')
    })

    // Set up clear button
    $('#clean').on('click', event => {
      event.stopPropagation()
      event.preventDefault()

      document.querySelectorAll('[input_clearable="true"]').forEach(node => {
        if (node.controller) node.controller.clear()
        else {
          $(node).val('')
          // Because select2 needs to be triggered... and don't trigger form!
          $(node).trigger('change', { update: false })
        }
      })

      // Update results
      searchButton.trigger('click')
    })

    // Set up automatic search after changing the text and 500ms later
    let termTimeout
    $('#name_filter input[manager-search="term"]').on('input', () => {
      if (termTimeout) clearTimeout(termTimeout)
      termTimeout = setTimeout(() => {
        searchButton.trigger('click')
      }, 500)
    })

    // Set up text clean
    $('#name_filter .input--clean').on('click', event => {
      event.stopPropagation()
      event.preventDefault()

      $('[manager-search="term"]').val('')

      // Update results
      searchButton.trigger('click')
    })

    // On enter, click on search button
    document.addEventListener('keypress', function (event) {
      if (event.key === 'Enter') {
        event.preventDefault()
        searchButton.trigger('click')
      }
    })

    const managerName = document.getElementById('manager_name')
    const managerEmail = document.getElementById('manager_email')
    const managerKind = $('#manager_kind')
    const inviteButton = $('#send_invite_button')[0]
    
    const checkFormFilled = () => {
      if(inviteButton.getAttribute("ismanagerdemo") === "false" && managerName.value !== "" && managerEmail.value !== ""){
        // Check for each selected kind
        switch (managerKind.val()) {
          case '':
            inviteButton.disabled = true
            break
          case '1':
            // Check if user can choose specific promotors
            if (document.getElementById("helper_management_promotor_ids")) {
              inviteButton.disabled = document.querySelectorAll('[management_promotor_ids]').length > 0 ? false : true
            } else {
              inviteButton.disabled = false
            }
            break
          case '2':
            // Check if user can choose specific venues
            if (document.getElementById("helper_management_venue_ids")) {
              inviteButton.disabled = document.querySelectorAll('[management_venue_id]').length > 0 ? false : true
            } else {
              inviteButton.disabled = false
            }
            break
          case '3':
            inviteButton.disabled = document.querySelectorAll('[management_event_id]').length > 0 ? false : true
            break
          default:
            inviteButton.disabled = false
            break
        }
      } else {
        inviteButton.disabled = true
      }
    }

    managerName.addEventListener('input', checkFormFilled)
    managerEmail.addEventListener('input', checkFormFilled)
    // Need to be jquery
    managerKind.on('change', checkFormFilled)
    $('#helper_management_event_ids').on('change', checkFormFilled)
    $('#helper_management_venue_ids').on('change', checkFormFilled)
    $('#helper_management_promotor_ids').on('change', checkFormFilled)

  }
})
