import setupTogglers from '../../../../services/accordion'
import NonBlockedOccupationLogicService from '../../../../services/non_blocked_occupation_logic_service'

$(document).on('ready', () => {
  if ($('#managers-events-index').length !== 0) {
    // On first page, clear bulk selected event ids
    document.querySelector('[name="is_bulk_select"]').value = false
    document.querySelector('[name="shown_bulk_event_ids"]').value = ''

    // Set up select changes
    $('[update_form_on_change=true]').on('change', function (_event, param1) {
      if (!param1 || !!param1.update) {
        // Clear hidden filter dates
        $('#hidden_filter_start_end_date').val(null)
        $('#hidden_op_filter_start_end_date').val(null)
        $('[name="has_params"]').val(true)

        // Reference: https://stackoverflow.com/a/15847260/13860978
        $('#filters').trigger('submit.rails')
      }
    })

    // Function for blocking selects and buttons
    const blockFilters = (block) => {
      // Block filters and buttons
      if (block) {
        document.querySelector('#search_button input').setAttribute('disabled', 'disabled')
        document.querySelector('#clear-filters').setAttribute('disabled', 'disabled')
        for (const select of document.querySelectorAll('[input_clearable="true"]')) {
          select.setAttribute('disabled', 'disabled')
          $(select).parent().find('.styled_select').attr('disabled', 'disabled')
        }
      } else {
        document.querySelector('#search_button input').removeAttribute('disabled')
        document.querySelector('#clear-filters').removeAttribute('disabled')
        for (const select of document.querySelectorAll('[input_clearable="true"]')) {
          select.removeAttribute('disabled')
          $(select).parent().find('.styled_select').attr('disabled', false)
        }
      }
    }

    // Set loading spinner when submitting form
    // Rails with JQuery submits twice...
    let storedValues = []
    const onFormSubmit = (e) => {
      $('[selector="loading-spinner"]').css('display', 'block')
      // Hide table
      $('[async-target="full-list"]').css('display', 'none')

      // Recuperate previous filter for blocking the filters
      const blockedElements = document.querySelectorAll('[input_clearable="true"]')
      if (storedValues.length === 0) {
        for (const el of blockedElements) {
          const val = $(el).val()
          if (!val) continue

          $(e.target).append(`<input type="hidden" name="${el.getAttribute('name')}" value="${$(el).val()}" form-selector="removable" />`)
        }
      }
      blockFilters(true)
    }

    blockFilters(true)
    $('#filters').on('submit', onFormSubmit)
    $('#filters').on('submit.rails', onFormSubmit)

    // Set up date range picker to update the hidden dates
    $('#filter_start_end_date').on('change', function () {
      $('#hidden_filter_start_end_date').val($(this).val())
    })
    $('#op_filter_start_end_date').on('change', function () {
      $('#hidden_op_filter_start_end_date').val($(this).val())
    })

    // Set daterangepicker filters clicking to submit form
    document.addEventListener('created.daterangepicker', (event) => {
      if (event.detail && event.detail.element && event.detail.element.getAttribute('date-apply-update') === 'true') {
        $(event.detail.element).on('apply.daterangepicker', (_event, _param1) => {
          $('#filters').trigger('submit.rails')
        })
      }
    })

    // Set loading spinner for pagination (and prevent full spinner)
    $(document).on('events-list-loaded', () => {
      $('.paginator .page-item a').on('click', () => {
        $('[selector="loading-spinner"]').css('display', 'block')
        // Hide table
        $('[async-target="full-list"]').css('display', 'none')
      })
      setupTogglers()
      blockFilters(false)
      $('[form-selector="removable"]').detach()
      storedValues = []

      new NonBlockedOccupationLogicService().setup()

      // Get all labels
      const labels = document.querySelectorAll('[data-label-select]')

      if (labels) {
        // Iterate each label
        labels.forEach(element => {
          // Remove label
          element.addEventListener('click', () => {
            // Clear dates_selected when we click on x to clear dates filters
            if (element.dataset.labelSelect == "filter_start_end_date") {
              $('[name="dates_selected_session"]').val(false)
            } else if (element.dataset.labelSelect == "op_filter_start_end_date") {
              $('[name="dates_selected_op"]').val(false)
            }
            // Get input or select
            const correspondingSelector = $(`select[id='${element.dataset.labelSelect}']`)
            // If length is diferent from zero means that we found a select
            if (correspondingSelector.length !== 0) {
              // Define new value for select
              const changeFunc = correspondingSelector.get(0).changeValue
              if (changeFunc) changeFunc('')
              else $(correspondingSelector).val('').trigger('change', [{ stop_update: true }])
            } else {
              // Otherwise we found an input
              const nodeSelector = $(`input[id='${element.dataset.labelSelect}']`)
              if (nodeSelector && nodeSelector.get(0)) nodeSelector.get(0).controller.clear()
              else {
                // Has not found any input
                const controllerSelector = $(`[select-controller="${element.dataset.labelSelect}"]`)
                if (controllerSelector && controllerSelector.get(0)) controllerSelector.get(0).controller.clear()
              }
            }

            // Submit form to update
            $('#filters').trigger('submit.rails')
          })
        })
      }

      // If filters exist set to url
      if ($('[name="url_filters"]').val()) {
        // Url path host + subpages
        let urlPath = window.location.origin + window.location.pathname
        urlPath = urlPath + '?' + $('[name="url_filters"]').val()
        // Push to history
        const url = new URL(urlPath)
        window.history.pushState({}, '', url)
      }
    })

    // On enter, click on search button
    document.addEventListener('keypress', function (event) {
      if (event.key === 'Enter') {
        event.preventDefault()
        document.querySelector('#search_button input').click()
      }
    })

    // Setup clearable search
    $('#name_filter .input--clean').get(0).onclick = () => {
      $("#name_filter input[name='term']").val('')
    }

    // Setup clearing filters button
    $('#clear-filters').on('click', () => {
      // Clear hidden filter dates
      $('#hidden_filter_start_end_date').val(null)
      $('#hidden_op_filter_start_end_date').val(null)

      $('[name="show_dates"]').val(false) // Reset the dates like the first page
      $('[name="dates_selected_session"]').val(false)
      $('[name="dates_selected_op"]').val(false)
      document.querySelectorAll('[input_clearable="true"]').forEach(node => {
        if (node.controller) node.controller.clear()
        else if (node.id === "is_active"){
        }
        else if (node.getAttribute('setup-select') === 'true') {
          const allOption = node.parentNode.querySelector('li[rel=""]')
          $(allOption).trigger('click', { update: false }) // Option on custom select
        } else {
          $(node).val('')
          $(node).trigger('change', { update: false }) // Because select2 needs to be triggered...
        }
      })

      // Update results
      // Reference: https://stackoverflow.com/a/15847260/13860978
      $('[name="has_params"]').val(true)
      $('#filters').trigger('submit.rails')
      $('[name="show_dates"]').val(true) // Make sure we can show the dates later!
    })

    // Set advanced search dropdown
    $('#advanced-search-dropdown').on('click', function () {
      $(this).toggleClass('opened')
      if ($(this).hasClass('opened')) {
        $('#advanced-search-filters-group').addClass('filters_events__opened')
      } else {
        $('#advanced-search-filters-group').removeClass('filters_events__opened')
      }
    })
  }
})
