import { Controller } from 'stimulus'

export default class extends Controller {
  currValue = null

  connect () {
    // This hack is used for showing the search bar conjugated with the multiple tag
    // HACK START
    // Set Dropdown with SearchBox via dropdownAdapter option (https://stackoverflow.com/questions/35799854/how-to-add-selectall-using-dropdownadapter-on-select2-v4)
    const Utils = $.fn.select2.amd.require('select2/utils')
    const Dropdown = $.fn.select2.amd.require('select2/dropdown')
    const DropdownSearch = $.fn.select2.amd.require('select2/dropdown/search')
    const CloseOnSelect = $.fn.select2.amd.require('select2/dropdown/closeOnSelect')
    const AttachBody = $.fn.select2.amd.require('select2/dropdown/attachBody')
    const dropdownAdapter = Utils.Decorate(Utils.Decorate(Utils.Decorate(Dropdown, DropdownSearch), CloseOnSelect), AttachBody)

    $(this.element).select2({
      placeholder: this.placeholder,
      allowClear: true,
      multiple: true,
      dropdownAdapter: dropdownAdapter,
      minimumResultsForSearch: 0,
      "language": {
        "noResults": function(){
            return "Sem resultados.";
        }
      },
    }).on('select2:opening select2:closing', function (event) {
      // Disable original search (https://select2.org/searching#multi-select)
      const searchfield = $(this).parent().find('.select2-search__field')
      searchfield.prop('disabled', true)
    })
    // HACK END

    // Hide initial option
    this.hideOption()

    // Set select2 filters clicking to submit form
    $(this.element).on('change', function (_event, param1) {
      this.hideOption()

      const newValue = $(this.element).val()
      if (this.currValue == newValue) return // Avoid repetitions

      this.currValue = newValue

      if (!param1 || !!param1.update) {
        // Clear hidden filter dates
        $('#hidden_filter_start_end_date').val(null)
        $('#hidden_op_filter_start_end_date').val(null)
        $('[name="has_params"]').val(true)

        // Reference: https://stackoverflow.com/a/15847260/13860978
        $('#filters').trigger('submit.rails')
      }
    }.bind(this))

    // Fix remove all options translation
    $('.select2-selection__clear').attr('title', 'Limpar')

    // HACK: Do not open select2 on clearing all options
    $(this.element).on('select2:unselecting', function() {
      $(this).data('unselecting', true)
    }).on('select2:open', function() {
      if ($(this).data('unselecting')) {
        $(this).select2('close').removeData('unselecting');
      }   
    })

    // Store initial value
    this.currValue = $(this.element).val()
  }

  get placeholder () {
    return this.element.dataset.placeholder || $.fn.select2.defaults.defaults.placeholder
  }

  get allowClear () {
    return this.element.dataset.allowClear || $.fn.select2.defaults.defaults.allowClear
  }

  get tags () {
    return this.element.dataset.tags || $.fn.select2.defaults.defaults.tags
  }

  get multiple () {
    return this.element.dataset.multiple || false
  }

  hideOption () {
    const element = $(this.element).parent()
    element.find('.select2-selection__choice').hide()
    const selected = $(this.element).find(':selected')
    const selectedLength = selected.length

    if (selectedLength === 0) {
      // Inject the placeholder when it is cleared
      element.find('.select2-selection__rendered').append(`<div id="length" class="select2__placeholder">${this.placeholder}</div>`)
    } else if (selectedLength === 1) {
      element.find('.select2-search--inline').hide()
      element.find('.select2-selection__rendered').append(`<div id="length" class="select2__placeholder">${selected.get(0).text}</div>`)
    } else if (selectedLength > 1) {
      element.find('.select2-search--inline').hide()
      element.find('.select2-selection__rendered').append(`<div id="length" class="select2__placeholder">${selectedLength} selecionados </div>`)
    }
  }
}
