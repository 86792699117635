import { CREATOR } from './constants.js.erb'

export default class Renderer {
  constructor (selector, options = {}) {
    const defaultOptions = {
      schema: { type: 'object', properties: {}, required: [] },
      item: 'dynamic_form',
      scopes: [],
      errors: {},
      data: {},
      previewMode: false
    }

    this.$renderer = $(selector)

    this.options = { ...defaultOptions, ...options, ...this.$renderer.data() }

    const sortedEntries = Object.entries(this.options.schema.properties).sort((a, b) => a[1].position - b[1].position)
    this.options.schema.properties = Object.fromEntries(sortedEntries)

    this.renderFieldByTypeObject = {
      [CREATOR.FIELD_TYPE_TEXT]: this.renderTextField.bind(this),
      [CREATOR.FIELD_TYPE_SELECT]: this.renderSelectField.bind(this),
      [CREATOR.FIELD_TYPE_SINGLE_CHECKBOX]: this.renderSingleCheckboxField.bind(this),
      [CREATOR.FIELD_TYPE_MULTIPLE_CHECKBOX]: this.renderMultipleCheckboxField.bind(this),
      [CREATOR.FIELD_TYPE_DATE]: this.renderDateField.bind(this),
      [CREATOR.FIELD_TYPE_RADIO]: this.renderRadioField.bind(this),
      [CREATOR.FIELD_TYPE_INCREMENTABLE]: this.renderIncrementableField.bind(this),
      [CREATOR.FIELD_TYPE_LICENSE_PLATE]: this.renderLicensePlateField.bind(this)
    }
  }

  updateSchema (schema) {
    this.options.schema = schema
  }

  render () {
    this.$renderer.empty()

    let hasRequiredField = false

    Object.keys(this.options.schema.properties).forEach((fieldName) => {
      let fieldEnum = []

      if (this.options.schema.properties[fieldName].type === 'array') {
        fieldEnum = this.options.schema.properties[fieldName].items.enum
      } else {
        fieldEnum = this.options.schema.properties[fieldName].enum
      }

      const fieldType = this.options.schema.properties[fieldName].field_type

      this.renderFieldByTypeObject[fieldType](fieldName, fieldEnum)

      hasRequiredField = hasRequiredField || this.options.schema.required.includes(fieldName)
    })

    window.setupSelects()
    window.setupErrors()
  }

  setNameAttr ($element, fieldName, multiple = false) {
    if (!this.options.previewMode) {
      const scopesString = this.options.scopes.reduce((acc, scope) => `${acc}[${scope}]`, '')
      $element.attr('name', `${this.options.item}${scopesString}[${fieldName}]${multiple ? '[]' : ''}`)
    }
  }

  setValueAttr ($element, fieldName) {
    const value = this.options.data[fieldName]
    if (value) $element.val(value)
  }

  /* FIELD RENDERERS */
  renderTextField (fieldName) {
    const $fieldDiv = $("<div class='dyn-viewer__field input'>")

    $fieldDiv.append(
      this.generateFieldName(fieldName),
      this.generateTextInput(fieldName)
    )

    this.$renderer.append($fieldDiv)
  }

  renderSelectField (fieldName, fieldEnum) {
    const $errorClass = this.options.errors[fieldName] ? 'form-control is-invalid' : 'form-control'
    const $fieldDiv = $("<div class='dyn-viewer__field input'>")
    const $selectDiv = $(`<div class="form-select select--dark previewer__select ${$errorClass}">`)
    const $error = this.generateError(fieldName)

    $selectDiv.append(this.generateSelect(fieldName, fieldEnum))
    $fieldDiv.append(this.generateFieldName(fieldName), $selectDiv, $error)

    this.$renderer.append($fieldDiv)
  }

  renderSingleCheckboxField (fieldName, text) {
    const $fieldDiv = $("<div class='dyn-viewer__field input'>")

    const $checkboxDiv = this.generateCheckbox(fieldName, text)
    $fieldDiv.append(this.generateFieldName(fieldName), $checkboxDiv)

    this.$renderer.append($fieldDiv)
  }

  renderMultipleCheckboxField (fieldName, fieldEnum) {
    const $fieldDiv = $("<div class='dyn-viewer__field input'>")
    const $fieldDivContainer = $("<div class='dyn-viewer__checkbox-list'>")
    $fieldDivContainer.append(this.generateMultipleCheckbox(fieldName, fieldEnum))

    if (this.options.errors[fieldName]) {
      $fieldDivContainer.addClass('dyn-viewer__checkbox-list--error')
      $fieldDivContainer.append(this.generateError(fieldName))
    }

    $fieldDiv.append(this.generateFieldName(fieldName), $fieldDivContainer)
    this.$renderer.append($fieldDiv)
  }

  renderDateField (fieldName) {
    const $fieldDiv = $("<div class='dyn-viewer__field input'>")

    $fieldDiv.append(
      this.generateFieldName(fieldName),
      this.generateDate(fieldName)
    )

    this.$renderer.append($fieldDiv)
    window.setupDatetimepicker($('.boostrap-calendar'))
  }

  renderRadioField (fieldName, fieldEnum) {
    const $fieldDiv = $("<div class='dyn-viewer__field input'>")
    const $fieldDivContainer = $("<div class='dyn-viewer__radio-list'>")

    $fieldDivContainer.append(this.generateRadios(fieldName, fieldEnum))
    $fieldDiv.append(this.generateFieldName(fieldName), $fieldDivContainer)

    this.$renderer.append($fieldDiv)
  }

  renderIncrementableField (fieldName) {
    const $fieldDiv = $("<div class='dyn-viewer__field input'>")

    $fieldDiv.append(
      this.generateFieldName(fieldName),
      this.generateIncrementable(fieldName)
    )

    this.$renderer.append($fieldDiv)
  }

  renderLicensePlateField (fieldName) {
    const $fieldDiv = $("<div class='dyn-viewer__field input'>")

    $fieldDiv.append(
      this.generateFieldName(fieldName),
      this.generateTextInput(fieldName, 'AA00AA')
    )

    this.$renderer.append($fieldDiv)
  }

  /* FIELD GENERATORS */
  generateFieldName (fieldName) {
    const fieldRequired = this.options.schema.required.includes(fieldName)
    return $(`<label class='text--main--gray font--extra_small text--bold--uppercase'>${(fieldRequired === true) ? (fieldName + ' *') : fieldName}</label>`)
  }

  generateTextInput (fieldName, placeholder) {
    const $placeholder = placeholder != null ? `placeholder=${placeholder}` : ''
    const $errorClass = this.options.errors[fieldName] ? 'form-control is-invalid' : 'form-control'
    const $textInput = $('<input class="form-input previewer__input tl_input--dark ' + $errorClass + '" ' + $placeholder + ' type="text">')

    this.setNameAttr($textInput, fieldName)
    this.setValueAttr($textInput, fieldName)

    const $error = this.generateError(fieldName)

    return [$textInput, $error]
  }

  generateSelect (fieldName, fieldEnum) {
    const $required = this.options.schema.properties[fieldName].required ? '' : 'disabled'
    const $select = $(
      `<select class="select">
        <option value="none" selected ${$required} hidden>Selecione uma opção</option>
        ${fieldEnum.map(
          (option) => `<option value="${option}">${option}</option>`
        )}
      </select>`
    )

    this.setNameAttr($select, fieldName)
    this.setValueAttr($select, fieldName)

    return [$select]
  }

  generateCheckbox (fieldName) {
    const text = this.options.schema.properties[fieldName].text
    const errorClass = this.options.errors[fieldName] ? 'form-control is-invalid' : 'form-control'

    const $label = $('<label class="tl_control tl_control_checkbox previewer__checkbox previewer__checkbox--single"></label>')
    const $checkbox = $(`<input type="checkbox" value="true" class="form-checkbox ${errorClass}">`)
    const $checkboxIndicator = $('<div class="tl_control_indicator tl_control_indicator--base tl_control_indicator--red"></div>')
    const $name = $(`<p class="text--dark--gray font--extra_small text--regular checkbox_text link">${text}</p>`)
    const $error = this.generateError(fieldName)

    this.setNameAttr($checkbox, fieldName)
    this.setValueAttr($checkbox, fieldName)

    $label.append([$checkbox, $checkboxIndicator, $name, $error])

    return $label
  }

  generateMultipleCheckbox (fieldName, fieldEnum) {
    return fieldEnum.map((option) => {
      const $label = $('<label class="tl_control tl_control_checkbox previewer__checkbox"></label>')
      const $checkbox = $(`<input type="checkbox" value=${option} class="form-multi-checkbox">`)
      const $option = $(`<p class="text--dark--gray font--extra_small text--regular checkbox_text link">${option}</p>`)
      const $indicator = $('<span class="tl_control_indicator tl_control_indicator--base tl_control_indicator--red" ></span>')

      this.setNameAttr($checkbox, fieldName, true)
      this.setValueAttr($checkbox, fieldName)

      $label.append([$checkbox, $option, $indicator])

      return $label
    })
  }

  generateDate (fieldName) {
    const $errorClass = this.options.errors[fieldName] ? 'form-control is-invalid' : 'form-control'
    const today = moment().format('YYYY-MM-DD')
    const $dateWrapper = $('<div class="form relative field previewer__date"></div>')
    const $dateInput = $(`<input class="form-input tl_input--dark previewer__input ${$errorClass}" type="text" value="${today}">`)

    $dateInput.datetimepicker({
      locale: 'pt',
      format: 'YYYY-MM-DD',
      icons: {
        next: 'fa fa-chevron-right',
        previous: 'fa fa-chevron-left'
      }
    })

    this.setNameAttr($dateInput, fieldName)
    this.setValueAttr($dateInput, fieldName)

    $dateWrapper.append($dateInput)
    const $error = this.generateError(fieldName)

    return [$dateWrapper, $error]
  }

  generateRadios (fieldName, fieldEnum) {
    return fieldEnum.map((option, i) => {
      const $label = $('<label class="tl_control tl_control_radio previewer__radio"></label>')
      const $option = $(`<p class="font--extra_small">${option}</p>`)
      const $radioInput = $(`<input type="radio" ${(i === 0) ? ('checked="checked"') : ('')} name="${fieldName}" value="${option}">`)
      const $indicator = $('<span class="tl_control_indicator tl_control_indicator--red"></span>')

      this.setNameAttr($radioInput, fieldName)
      this.setValueAttr($radioInput, fieldName)

      $label.append([$option, $radioInput, $indicator])

      return $label
    })
  }

  generateIncrementable (fieldName) {
    const $errorClass = this.options.errors[fieldName] ? 'form-control is-invalid' : 'form-control'
    const $numberInput = $(`<input class="form-input previewer__input tl_input--dark ${$errorClass}" type="number">`)

    this.setNameAttr($numberInput, fieldName)
    this.setValueAttr($numberInput, fieldName)

    const $error = this.generateError(fieldName)

    return [$numberInput, $error]
  }

  generateError (fieldName) {
    const errorMessage = (this.options.errors[fieldName] || []).join(', ').capitalize()
    return $(`<div class="invalid-feedback">${errorMessage}</div>`)
  }
}
