import EventSessions from '../partials/event_sessions'
import ReimportTicketsService from '../../../../services/reimport_tickets_service'
import NonBlockedOccupationLogicService from '../../../../services/non_blocked_occupation_logic_service'

$(document).on('ready', () => {
  if ($('#managers-events-show, #managers-events-event-sale-summary, #managers-events-event-sale-local, #managers-events-event-occupation, #managers-events-event-ticket-type, #managers-events-event-sales-per-pos, #managers-events-bulk-show').length !== 0) {
    $('.full-spinner').css('display', 'none');
    
    new EventSessions().setup()
    new NonBlockedOccupationLogicService().setup()

    // Setup modal for event ticket verification
    const modal = $('#ticket-verification-modal')
    $('[selector="ticket-verification-button"]').on('click', function (event) {
      event.stopPropagation()
      event.preventDefault()

      const url = this.getAttribute('href')
      // Send request and update modal
      $.ajax({ url: url }).done((data) => {
        modal.find('[selector="modal-message"]').html(data)
      })
    })

    // Setup progress bar for ticket reimport
    const reimportTicketsService = new ReimportTicketsService()
    reimportTicketsService.setup($('[selector="import-progress"]')?.get(0))
    const reimportTicketsButton = $('[selector="reimport-tickets-button"]')
    reimportTicketsButton.on('click', () => {
      reimportTicketsService.setupInterval()
      reimportTicketsButton.hide()
    })

    // Animate scroll to the left
    // Get the event card with the leaf-event-scroll equals true and move to its position minus 20 px (allow to show the entire card)
    const eventToScrollTo = document.querySelector("[leaf-event-scroll='true']")
    if (eventToScrollTo) $('.event--scroll').animate({ scrollLeft: eventToScrollTo.offsetLeft - 20 }, 500)

    const aggregatedEventsToggler = document.querySelector(
      "[selector='aggregated_events_sales_toggler']"
    )

    if (aggregatedEventsToggler) {
      aggregatedEventsToggler.addEventListener('click', function (ev) {
        const hasSales = ev.target.checked

        // Hide cards that does not have sales
        document
          .querySelectorAll("[leaf-event-has-sales='false']")
          .forEach((el) => {
            if (hasSales) {
              el.style.display = 'none'
            } else {
              el.style.display = ''
            }
          })

        // Change number of aggregated cards
        document.querySelector(
          '[selector="aggregated_events_header"]'
        ).style.display = !hasSales ? '' : 'none'
        document.querySelector(
          '[selector="aggregated_events_only_sales_label_header"]'
        ).style.display = hasSales ? '' : 'none'

        document.querySelector(
          '[selector="aggregated_events_only_sales_label_selected_count"]'
        ).style.display = !hasSales ? '' : 'none'
        document.querySelector(
          '[selector="aggregated_events_only_sales_label_subtitle"]'
        ).style.display = hasSales ? '' : 'none'
      })
    }
    $('#suffix_add').on('click', ()=> {
      $('#suffix_add').hide()
      $("#suffix_save_cancel").css('display', "flex")
      $('#suffix_field').show()
      $('#suffix').prop('disabled', false)
    })

    $("#suffix_edit").on('click', ()=> {
      $('#suffix').prop('disabled', false)
      $("#suffix_errase_edit").hide()
      $("#suffix_save_cancel").css('display', "flex")
    })

    $("#suffix_cancel").on('click', ()=> {
      if($('#suffix').attr("value")){
        $('#suffix').val($('#suffix').attr("value"))
        $('#suffix').prop('disabled', true)
        $("#suffix_save_cancel").hide()
        $("#suffix_errase_edit").css('display', "flex")
      }else{
        $("#suffix_save_cancel").hide()
        $('#suffix_add').show()
        $('#suffix_field').hide()
      }
    })

    $("#suffix").on('input', () => {
      if ($('#suffix').val().indexOf(' ') >= 0){
        $("#suffix_save").prop('disabled', true)
        $("#suffix_error").show()
      } else {
        $("#suffix_save").prop('disabled', false)
        $("#suffix_error").hide()
      }
    })

    $('#mobile_suffix_add').on('click', ()=> {
      $('#mobile_suffix_add').hide()
      $("#mobile_suffix_save_cancel").css('display', "flex")
      $('#mobile_suffix_field').show()
      $('#mobile_suffix').prop('disabled', false)
    })

    $("#mobile_suffix_edit").on('click', ()=> {
      $('#mobile_suffix').prop('disabled', false)
      $("#mobile_suffix_errase_edit").hide()
      $("#mobile_suffix_save_cancel").css('display', "flex")
    })

    $("#mobile_suffix_cancel").on('click', ()=> {
      if($('#mobile_suffix').attr("value")){
        $('#mobile_suffix').val($('#suffix').attr("value"))
        $('#mobile_suffix').prop('disabled', true)
        $("#mobile_suffix_save_cancel").hide()
        $("#mobile_suffix_errase_edit").css('display', "flex")
      }else{
        $("#mobile_suffix_save_cancel").hide()
        $('#mobile_suffix_add').show()
        $('#mobile_suffix_field').hide()
      }
    })

    $("#mobile_suffix").on('input', () => {
      if ($('#mobile_suffix').val().indexOf(' ') >= 0){
        $("#mobile_suffix_save").prop('disabled', true)
        $("#mobile_suffix_error").show()
      } else {
        $("#mobile_suffix_save").prop('disabled', false)
        $("#mobile_suffix_error").hide()
      }
    })
  }
})
