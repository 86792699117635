$(document).on('ready', () => {
  if ($('#admin-loop_venues-index').length !== 0) {
    $('.toggler').on('click', function (ev) {
      ev.preventDefault()
      var venue_id = $(this).attr('venue_id')
      $('#arrow_' + venue_id).toggleClass('down')
      if ($('.venue_' + venue_id).hasClass('dropdown__slide')) {
        if ($('.venue_' + venue_id).hasClass('dropdown__slide--open')) {
          $('.venue_' + venue_id).removeClass('dropdown__slide--open')
          $('.venue_' + venue_id).addClass('dropdown__slide--close')
          $('.venue_' + venue_id).addClass('dropdown__slide--no-border')
        } else {
          $('.venue_' + venue_id).removeClass('dropdown__slide--close')
          $('.venue_' + venue_id).addClass('dropdown__slide--open')
          $('.venue_' + venue_id).removeClass('dropdown__slide--no-border')
        }
      } else {
        $('.venue_' + venue_id).stop().slideToggle()
      }
    })
  }
})
