import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ["triggerWrapper", "modal"]

  connect () {
    this.confirmed = false
    this.triggerTarget = this.triggerWrapperTarget?.firstElementChild
    if (this.triggerTarget) this.triggerTarget.onclick = this.onClick.bind(this)
    this.element.controller = this

    if (this.showStartup) this.show()
  }

  onClick (event) {
    if (this.enabled && !this.confirmed) {
      this.show()
      event.stopPropagation()
      event.preventDefault()
    }
  }

  show () {
    if (this.enabled) {
      this.shown = true
      this.modalTarget.style.display = 'block'
      this.modalTarget.addEventListener('click', (e) =>  this.backdropClick(e))

      $(document).keyup((e) => {
        if (e.key === "Escape" && this.shown) { // escape key maps to keycode `27`
          this.hide(e)
       }
      })
    }
  }

  hide (event) {
    this.modalTarget.style.display = 'none'
    event.preventDefault()
    this.shown = false
  }

  confirm () {
    this.confirmed = true
    this.triggerTarget.click()
  }

  backdropClick(event) {
    event.target === this.modalTarget && this.hide(event)
  }

  get enabled () {
    return this.element.dataset.enabled === 'true'
  }

  get showStartup () {
    return this.element.dataset.show === 'true'
  }
}
