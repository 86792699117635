$(document).on('ready', () => {
  // Setup manager masquerade select
  const masqueradeSelect = $('[select2-target="managers-masquerade-id"]')
  masqueradeSelect.select2({
    width: null
  })

  // On manager masquerade select change, masquerade or rollback masquerade
  const masqueradeForm = $('#masquerade-user-form')
  masqueradeSelect.on('change', () => masqueradeForm.trigger('submit'))
})
