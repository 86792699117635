import Base from '../../../base'
class Form extends Base {
  setup () {
    const $visibilityStatusSelect = $('#visibility_status_select').first()

    $visibilityStatusSelect.on('change', function ($ev) {
      const selectEl = $ev.target
      const $select = $(selectEl)
      const visibilityStatus = $select.val()

      if (visibilityStatus == 2) {
        $('#dates').css({ display: 'block' })
      } else {
        $('#dates').css({ display: 'none' })
      }
    })
  }
}

export default Form
