import Base from '../../../base'
import { VISIBILITY_STATUS_BETWEEN_DATES } from '../../../../constants/admins/carousel_item'

class Form extends Base {
  setup () {
    const $visibilityStatusSelect = $('#visibility_status_select').first()

    $visibilityStatusSelect.on('change', function ($ev) {
      const selectEl = $ev.target
      const $select = $(selectEl)
      const visibilityStatus = parseInt($select.val())

      if (visibilityStatus === VISIBILITY_STATUS_BETWEEN_DATES) {
        $('#dates').css({ display: 'block' })
      } else {
        $('#dates').css({ display: 'none' })
      }
    })

    const $submitModal = $("[data-controller='components--confirm-modal']")
    const enableSubmitModal = () => $submitModal.attr('data-components--confirm-modal-enabled-value', true)
    const disableSubmitModal = () => $submitModal.attr('data-components--confirm-modal-enabled-value', false)

    const $carouselItemEventId = $('#carousel_item_event_id')
    $carouselItemEventId.trigger('change') // this is to update the confirm modal when errors are appearing

    $carouselItemEventId.on('change', function (ev) {
      var eventId = ev.target.selectedOptions[0]
      if (eventId === undefined) {
        $carouselItemEventId.empty()
        enableSubmitModal()
      } else if (eventId.value === -1) {
        $carouselItemEventId.empty()
        enableSubmitModal()
      } else {
        var success = function (event) {
          moment.locale('pt')

          const generateMomentDate = date => moment(new Date(date)).format('DD/MM/YYYY')
          const venues = event.locations.map(location => {
            if (location.room_name) {
              return location.venue_name
            } else {
              return location.venue_name
            }
          }).join(', ')

          $('#carousel_item_name').val(event.name)
          $('#carousel_item_venue_name').val(venues)
          $('#carousel_item_youtube_url').val(event.youtube_url)
          $('#carousel_item_vimeo_url').val(event.vimeo_url)
          $('#carousel_item_start_date').val(generateMomentDate(event.start_date))
          $('#carousel_item_end_date').val(generateMomentDate(event.end_date))
          $('#banner_img').attr('src', (event.banner ? event.banner.path : ''))
        }

        const url = `/admin/carousel_items/events/${eventId.value}`
        $.get(url, {}, success, 'json')

        disableSubmitModal()
      }
    })
  }
}

export default Form
