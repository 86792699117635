import { Controller } from 'stimulus'

export default class extends Controller {
  divSelectEl = null
  selectEl = null
  listEl = null
  listHintEl = null
  spinnerEl = null
  allOptions = []
  selectedOptions = new Set()

  connect () {
    this.element.controller = this
    this.setup()
  }

  setup () {
    // Existing spinner
    this.spinnerEl = $(this.element).find('#spinner')

    // Setup select
    const selectEl = this.selectEl = $('<select>')
    const divSelectEl = this.divSelectEl = $('<div>').prependTo(this.element)
    selectEl.appendTo(divSelectEl)
    selectEl.select2({
      width: 'none',
      placeholder: this.placeholder,
      ajax: this.ajax,
      data: this.dataOptions,
      escapeMarkup: (m) => m
    }).val(null).trigger('change')
    

    // Store data options
    if (this.dataOptions.length > 0) this.allOptions = this.dataOptions

    divSelectEl.addClass('form-group')
    if (this.selectClasses) selectEl.addClass(this.selectClasses)
    else selectEl.addClass('new_manager__selector--select2 form-select2')

    // Setup select behavior
    const controller = this
    selectEl.on('change', function (_ev) {
      const itemId = selectEl.val()
      if (itemId == null) return
  
      controller.addItemToList(itemId)
      $(selectEl).val(null).trigger('change')
    })

    // Setup list element
    const listEl = this.listEl = $('<div>')
    this.listEl.addClass('empty-container')
    listEl.addClass(this.listClass)
    divSelectEl.after(listEl)

    const listTitle = $('<label class="new_manager__label text-tiny text--bold--uppercase"></label>')
    listTitle.text(this.listTitle)
    listEl.append(listTitle)

    const listHint = this.listHintEl = $('<p></p>')
    listHint.text(this.listHint)
    listEl.append(listHint)
    listHint.hide() // Hide on loading

    // Append existing items after getting results, check this.ajax()
    this.selectEl.select2('open')
    this.selectEl.select2('close')
  }

  setupList() {
    // Remove empty class of list container
    this.listEl.removeClass('empty-container')

    Object.values(this.listItems).map(i => this.addItemToList(i.toString()))
    if (this.selectedOptions.size === 0) this.listHintEl.show()
  }

  addItemToList(itemId) {
    const selectedOption = this.allOptions.find(o => o.id.toString() === itemId)
    const prevSelectedOption = this.listEl.find(`input[value='${itemId}']`)

    if (selectedOption === undefined || prevSelectedOption.length !== 0) return
    
    this.selectedOptions.add(selectedOption)
    // Update hint to hide if we have items 
    if (this.selectedOptions.size > 0) this.listHintEl.hide()

    const newElement = $('<div>')
    newElement.attr('data-item-id', itemId)
    newElement.addClass(this.listItemClass)
    newElement.appendTo(this.listEl)
    
    // Add delete icon
    const deleteIcon = $('<i>')
    deleteIcon.addClass('mngmt_kind__remove pointer')
    deleteIcon.addClass('fal fa-trash-alt')
    deleteIcon.on('click', (event) => {
      const iconEl = $(event.target)
      this.selectedOptions.delete(selectedOption)
      iconEl.closest('[data-item-id]').remove()
      if (this.selectedOptions.size === 0) this.listHintEl.show()
    })
    deleteIcon.appendTo(newElement)

    // Add item name or template
    if (!this.itemTemplate) {
      const nameSpan = $('<span>')
      nameSpan.addClass('on-left')
      nameSpan.text(selectedOption.text)
      nameSpan.appendTo(newElement)
    }
    else {
      newElement.append($(selectedOption.text))
    }

    // <input multiple='multiple' type='hidden' name='event_ids[]' value='<%= event.id %>'>
    const inputEl = $('<input>')
    inputEl.attr('multiple', 'multiple')
    inputEl.attr('type', 'hidden')
    inputEl.attr('name', `${this.paramId}[]`)
    inputEl.attr('value', itemId)
    inputEl.appendTo(newElement)

  }

  clearList() {
    const controller = this
    Array.from(this.selectedOptions).forEach(option => {
      controller.selectedOptions.delete(option)
      controller.listEl.find(`[data-item-id='${option.id}']`).remove()
    })
  }

  closeSpinner() {
    this.spinnerEl.css('display', 'none')
  }

  get placeholder () {
    return this.element.dataset.placeholder || $.fn.select2.defaults.defaults.placeholder
  }

  get ajax () {
    const controller = this
    if (this.element.dataset.ajaxUrl) {
      return {
        url: this.element.dataset.ajaxUrl,
        dataType: this.element.dataset.ajaxDataType || 'json',
        delay: 250,
        data: params => Object.assign({}, params, JSON.parse(this.element.dataset.ajaxData || null)),
        processResults: (data) => {
          const template = controller.itemTemplate
          const formattedData = !template ? data : ({ results: $.map(data.results, template) })

          controller.allOptions = formattedData.results
          controller.setupList() // Sets up the existing events after getting the data
          
          controller.closeSpinner()
          
          return formattedData
        }
      }
    } else {
      return null
    }
  }

  get dataOptions () {
    // Set template if required
    const template = this.itemTemplate
    const data = JSON.parse(this.element.dataset.dataOptions || '[]')
    return !template ? data : $.map(data, template)
  }

  get paramId () {
    return this.element.dataset.paramId
  }

  get listTitle () {
    return this.element.dataset.listTitle
  }

  get listHint () {
    return this.element.dataset.listHint
  }

  get listItems () {
    return JSON.parse(this.element.dataset.listItems || '[]')
  }

  get listClass () {
    return this.element.dataset.listClass
  }

  get listItemClass () {
    return this.element.dataset.listItemClass
  }

  get selectClasses () {
    return this.element.dataset.class
  }

  get itemTemplate () {
    const templates = {}
    templates["eventItem"] = (event) => (
      {
        id: event.id,
        // Reference: https://stackoverflow.com/questions/36360783/how-to-render-html-in-select2-options
        // You can customize this text to have HTML, but do not change the property name or other properties.
        text: `<span><div>
          <span class="text-bold">${event.tl_id_espec} · ${event.name}</span><br> ${event.venues} · (${event.dates})
        </div></span>`
      }
    )
    return templates[this.element.dataset.itemTemplate]
  }
}