import * as Routes from 'routes.js.erb'

$(document).on('ready', () => {
    if ($('#managers-restricted_pages-saft').length !== 0) {
        // Setup saft promotor select
        $("[select2-target='saft_promotor_id']").select2({width: null})
        $("#saft_year").on('change', () => {
            $('[selector="loading-spinner"]').css('display', 'block')
            // Hide table
            $('[async-target="full-list"]').css('display', 'none')
            
            const $promotor_tl_id = $("#promotor_id")
            const $year = $('#saft_year')

            $.ajax({
                type: 'POST',
                url: Routes.manager_saft_list_path(),
                data: {
                  content: 'js',
                  promotor_id: $promotor_tl_id.val(), 
                  saft_year: $year.val()
                }
              })
        })
    }
})
