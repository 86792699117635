export default function setupTogglers () {
  $('.toggler').on('click', function (ev) {
    ev.preventDefault()
    var eventId = $(this).attr('event_id')
    var parent = $(this).parent()
    $('#arrow_' + eventId).toggleClass('down')

    if ($('.event_' + eventId).hasClass('dropdown__slide')) {
      if ($('.event_' + eventId).hasClass('dropdown__slide--open')) {
        $('.event_' + eventId).removeClass('dropdown__slide--open')
        parent.removeClass('active')
        $('.event_' + eventId).addClass('dropdown__slide--close')
        $('.event_' + eventId).addClass('dropdown__slide--no-border')
      } else {
        $('.event_' + eventId).removeClass('dropdown__slide--close')
        $('.event_' + eventId).addClass('dropdown__slide--open')
        parent.addClass('active')
        $('.event_' + eventId).removeClass('dropdown__slide--no-border')
      }
    } else {
      $('.event_' + eventId).stop().slideToggle()
    }
  })
}
