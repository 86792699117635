$(document).on('ready', () => {
  if ($('#managers-config_panel-index').length !== 0) {
    // Setup refresh button
    const refreshButton = $('[selector="update-online-managers-button"]')
    refreshButton.on('click', () => {
      refreshButton.find('i').toggleClass('fa-spin')
      $.ajax({ url: refreshButton.attr('data-url') }).done(data => {
        $('#online-managers-container').html(data)
        setTimeout(() => refreshButton.find('i').toggleClass('fa-spin'), 200)
      })
    })
  }
})
