$(document).on('ready', () => {
  if ($('#events-index').length !== 0) {
    const $arrow = $('#arrow')
    const $advancedSearchContainer = $("[selector='advanced_search']")

    $('.toggler').on('click', ev => {
      ev.preventDefault()

      // Flip arrow
      if ($arrow.hasClass('fa-chevron-down')) {
        $arrow.removeClass('fa-chevron-down')
        $arrow.addClass('fa-chevron-up')
      } else {
        $arrow.removeClass('fa-chevron-up')
        $arrow.addClass('fa-chevron-down')
      }

      // Toggle advanced search
      if ($advancedSearchContainer.hasClass('dropdown__slide')) {
        if ($advancedSearchContainer.hasClass('dropdown__slide--open')) {
          $advancedSearchContainer.removeClass('dropdown__slide--open')
          $advancedSearchContainer.addClass('dropdown__slide--close')
          $advancedSearchContainer.addClass('dropdown__slide--no-border')
        } else {
          $advancedSearchContainer.removeClass('dropdown__slide--close')
          $advancedSearchContainer.addClass('dropdown__slide--open')
          $advancedSearchContainer.removeClass('dropdown__slide--no-border')
        }
      } else {
        $advancedSearchContainer.stop().slideToggle()
      }
    })

    // Update end_date min date on start_date change
    $('#start_date').on('dp.change', ev => {
      $('#end_date').data('DateTimePicker').minDate(ev.date)
    })
  }
})
