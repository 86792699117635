import { CREATOR } from './constants.js.erb'

export default class Creator {
  constructor (formSelector, previewRenderer = null) {
    this.$form = $(formSelector).first()
    this.$addFieldButton = this.$form.find("[dynamic-form-creator='add_field']").first()
    this.$fieldsWorkshop = this.$form.find("[dynamic-form-creator='fields_workshop']").first()
    this.$schema = this.$form.find("[dynamic-form-creator='schema']").first()
    this.previewRenderer = previewRenderer
    this.collapsedState = {}
    this.tinymceSelectors = {}

    const schema = this.$schema.val()
    this.schema = schema ? JSON.parse(schema) : CREATOR.DEFAULT_SCHEMA

    const sortedEntries = Object.entries(this.schema.properties).sort((a, b) => a[1].position - b[1].position)
    this.schema.properties = Object.fromEntries(sortedEntries)

    this.renderFieldByTypeObject = {
      [CREATOR.FIELD_TYPE_TEXT]: this.renderSingleValueField.bind(this),
      [CREATOR.FIELD_TYPE_SELECT]: this.renderMultiValueField.bind(this),
      [CREATOR.FIELD_TYPE_SINGLE_CHECKBOX]: this.renderSingleValueField.bind(this),
      [CREATOR.FIELD_TYPE_MULTIPLE_CHECKBOX]: this.renderMultiValueField.bind(this),
      [CREATOR.FIELD_TYPE_DATE]: this.renderSingleValueField.bind(this),
      [CREATOR.FIELD_TYPE_RADIO]: this.renderMultiValueField.bind(this),
      [CREATOR.FIELD_TYPE_INCREMENTABLE]: this.renderSingleValueField.bind(this),
      [CREATOR.FIELD_TYPE_LICENSE_PLATE]: this.renderSingleValueField.bind(this)
    }
  }

  setup () {
    this.$addFieldButton.get(0).onmouseup = this.addField.bind(this)
    Object.keys(this.schema.properties).length ? this.render() : this.addField()
  }

  sortPropertiesByPosition (callback = null) {
    let entries = Object.entries(this.schema.properties).sort((a, b) => a[1].position - b[1].position)
    if (callback) entries = callback(entries)
    this.schema.properties = Object.fromEntries(entries)
  }

  setGlobalCollapseState (state) {
    Object.keys(this.collapsedState).forEach(fieldName => {
      if (fieldName !== '') this.collapsedState[fieldName] = state
    })
  }

  toggleFieldCollapseState (fieldName, $toggle) {
    const $content = $toggle.parents('.dyn-form').find('.dyn-form__content')
    const $arrow = $toggle.find('.rotate')

    $content.slideToggle('fast')
    $arrow.toggleClass('down')

    this.collapsedState[fieldName] = $arrow.hasClass('down')
  }

  getFieldEnum (fieldName) {
    if (this.schema.properties[fieldName].type === 'array') {
      return this.schema.properties[fieldName].items.enum
    } else {
      return this.schema.properties[fieldName].enum
    }
  }

  setFieldEnum (fieldName, newEnum) {
    const enumSet = new Set(newEnum)

    if (this.schema.properties[fieldName].type === 'array') {
      this.schema.properties[fieldName].items.enum = [...enumSet]
    } else {
      this.schema.properties[fieldName].enum = [...enumSet]
    }
  }

  /* ADD FIELD */
  addField () {
    this.schema.properties[''] = { ...FIELD_TYPE_SCHEMA_OBJECT[CREATOR.FIELD_TYPE_TEXT] }
    this.schema.properties[''].position = Object.keys(this.schema.properties).length - 1
    this.setGlobalCollapseState(true)
    this.render()
  }

  addSelectFieldOption (fieldName) {
    const enums = this.getFieldEnum(fieldName)
    enums.push('')

    this.setFieldEnum(fieldName, enums)
    this.render()
  }

  /* UPDATE FIELD */
  updateFieldName (oldFieldName, $fieldNameInput) {
    const newFieldName = $fieldNameInput.val()

    this.schema.properties[newFieldName] = { ...this.schema.properties[oldFieldName] }

    if (oldFieldName !== newFieldName) {
      delete this.schema.properties[oldFieldName]

      if (this.schema.required.includes(oldFieldName)) {
        this.schema.required.splice(this.schema.required.indexOf(oldFieldName), 1, newFieldName)
      }
    }

    this.setGlobalCollapseState(true)
    this.collapsedState[newFieldName] = false

    this.render()
  }

  updateFieldType ($event, fieldName) {
    const newFieldType = $($event.target).val()
    this.schema.properties[fieldName] = { ...FIELD_TYPE_SCHEMA_OBJECT[newFieldType] }
    if (newFieldType === CREATOR.FIELD_TYPE_SINGLE_CHECKBOX) { this.schema.properties[fieldName].text = '' }
    this.render()
  }

  updateFieldRequired (fieldName, $fieldRequiredCheckbox) {
    const required = $fieldRequiredCheckbox.is(':checked')

    if (required) {
      this.schema.required.push(fieldName)
    } else {
      this.schema.required.splice(this.schema.required.indexOf(fieldName), 1)
    }

    switch (this.schema.properties[fieldName].type) {
      case 'array':
        this.schema.properties[fieldName].minItems = required ? 1 : 0
        break

      default:
        this.schema.properties[fieldName].minLength = required ? 1 : 0
        break
    }

    this.render()
  }

  updateSelectFieldOptionName (fieldName, oldOptionName, $optionNameInput) {
    const enums = this.getFieldEnum(fieldName)
    this.setFieldEnum(fieldName, enums.map(e => e === oldOptionName ? $optionNameInput.val() : e))
    this.render()
  }

  updateText (fieldName, newText) {
    if (tinyMCE.activeEditor) { newText = tinyMCE.activeEditor.getContent() }
    this.schema.properties[fieldName].text = newText
    this.render()
  }

  /* REMOVE FIELD */
  removeField (fieldName) {
    delete this.schema.properties[fieldName]
    this.schema.required.splice(this.schema.required.indexOf(fieldName), 1)

    this.render()
  }

  removeSelectFieldOption (fieldName, option) {
    const enums = this.getFieldEnum(fieldName)
    this.setFieldEnum(fieldName, enums.filter((e) => e !== option))
    this.render()
  }

  /* RENDERERS */
  render (sortCallback = null) {
    this.sortPropertiesByPosition(sortCallback)
    this.$schema.val(JSON.stringify(this.schema))
    this.renderFieldsWorkshop()
    this.renderPreview()
  }

  renderPreview () {
    if (this.previewRenderer) {
      this.previewRenderer.updateSchema(this.schema)
      this.previewRenderer.render()
    }
  }

  renderSingleValueField (fieldName) {
    const $fieldDiv = $('<div class="card dyn-form">')

    /* TOP */
    const $fieldTop = $('<div class="dyn-form__top">')
    $fieldDiv.append($fieldTop)

    /* TOP - SIDEBAR */
    const $fieldSidebar = $('<div class="dyn-form__sidebar pl-3 pr-1">')
    $fieldSidebar.append(this.generateDragIcon())
    $fieldTop.append($fieldSidebar)

    /* TOP - MAIN */
    const $fieldMain = $('<div class="dyn-form__main">')
    $fieldTop.append($fieldMain)

    /* TOP - MAIN - HEADER */
    const $fieldHeader = $('<div class="dyn-form__header p-3">')
    $fieldHeader.append(this.generateFieldHeaderTitle(fieldName), this.generateFieldRemove(fieldName))
    $fieldMain.append($fieldHeader)

    /* TOP - MAIN - CONTENT */
    const $fieldContent = $('<div class="dyn-form__content dyn-content pl-3 pr-3 pb-3">')
    if (this.collapsedState[fieldName]) $fieldContent.css('display', 'none')
    $fieldMain.append($fieldContent)

    /* TOP - MAIN - CONTENT - INPUTS */
    const $fieldInputs = $('<div class="dyn-content__inputs">')
    $fieldInputs.append(this.generateFieldName(fieldName), this.generateFieldType(fieldName), this.generateFieldRequired(fieldName))

    /* Checkbox Text Field */
    const $fieldCheckboxText = $('<div class="dyn-content__checkbox-text mt-3">')
    if (this.schema.properties[fieldName].field_type === CREATOR.FIELD_TYPE_SINGLE_CHECKBOX) {
      $fieldCheckboxText.append(this.generateFieldText(fieldName))
    }

    $fieldContent.append($fieldInputs, $fieldCheckboxText)

    /* FOOTER */
    const $fieldFooter = $('<div class="dyn-form__footer">')
    $fieldFooter.append(this.generateFieldCollapseButton(fieldName))
    $fieldDiv.append($fieldFooter)

    this.$fieldsWorkshop.append($fieldDiv)

    if (this.schema.properties[fieldName].field_type === CREATOR.FIELD_TYPE_SINGLE_CHECKBOX) {
      this.setupTinyMCE(fieldName)
    }
  }

  renderMultiValueField (fieldName) {
    const $fieldDiv = $('<div class="card dyn-form">')
    const $fieldTop = $('<div class="dyn-form__top">')
    const $fieldMain = $('<div class="dyn-form__main">')
    const $fieldSidebar = $('<div class="dyn-form__sidebar pl-3 pr-1">')
    const $fieldHeader = $('<div class="dyn-form__header p-3">')
    const $fieldContent = $('<div class="dyn-form__content dyn-content p-3">')
    const $fieldInputs = $('<div class="dyn-content__inputs">')
    const $fieldOptionsContent = $('<div class="dyn-content__options pt-3">')
    const $fieldFooter = $('<div class="dyn-form__footer">')

    if (this.collapsedState[fieldName]) $fieldContent.css('display', 'none')

    $fieldHeader.append(this.generateFieldHeaderTitle(fieldName), this.generateFieldRemove(fieldName))

    $fieldInputs.append(
      this.generateFieldName(fieldName),
      this.generateFieldType(fieldName),
      this.generateFieldRequired(fieldName)
    )

    $fieldOptionsContent.append(this.generateFieldOptions(fieldName))

    $fieldContent.append($fieldInputs, $fieldOptionsContent)

    $fieldMain.append($fieldHeader, $fieldContent)

    $fieldSidebar.append(this.generateDragIcon)

    $fieldTop.append($fieldSidebar, $fieldMain)

    $fieldFooter.append(this.generateFieldCollapseButton(fieldName))

    $fieldDiv.append($fieldTop, $fieldFooter)

    this.$fieldsWorkshop.append($fieldDiv)
  }

  generateDragIcon () {
    const $fieldDragIcon = $("<i class='fas fa-grip-vertical'></i>")
    return $fieldDragIcon
  }

  /* FIELDS WORKSHOP */
  renderFieldsWorkshop () {
    this.$fieldsWorkshop.empty()

    Object.keys(this.schema.properties).forEach(fieldName => {
      const fieldType = this.schema.properties[fieldName].field_type
      this.renderFieldByTypeObject[fieldType](fieldName)
    })

    Sortable.create(this.$fieldsWorkshop.get(0), {
      onSort: event => {
        const { oldIndex, newIndex } = event

        this.render(entries => {
          const entry = entries.splice(oldIndex, 1)[0]
          entries.splice(newIndex, 0, entry)
          entries.forEach((entry, index) => (entry[1].position = index))
          return entries
        })
      }
    })

    window.setupSelects()
    this.setupToggles()
  }

  setupToggles () {
    $("[data-toggle='toggle']").bootstrapToggle()
  }

  /* FIELD HEADER TITLE */
  generateFieldHeaderTitle (fieldName) {
    const fieldHeaderTitleText = fieldName || 'Nome do Campo'
    const $fieldHeaderTitle = $(`<h5><strong>${fieldHeaderTitleText}</strong></h5>`)

    return $fieldHeaderTitle
  }

  generateFieldCollapseButton (fieldName) {
    const $fieldCollapseButtonContainer = $("<div class='collapse-area pt-1 pb-1'></div>")
    const $fieldCollapseButtonIcon = $("<i class='collapse-arrow rotate fas fa-caret-up'></i>")

    if (this.collapsedState[fieldName]) $fieldCollapseButtonIcon.addClass('down')

    $fieldCollapseButtonContainer.append($fieldCollapseButtonIcon)
    $fieldCollapseButtonContainer.get(0).onclick = this.toggleFieldCollapseState.bind(
      this,
      fieldName,
      $fieldCollapseButtonContainer
    )

    return $fieldCollapseButtonContainer
  }

  /* FIELD REMOVE */
  generateFieldRemove (fieldName) {
    const $fieldRemoveButton = $("<i class='fal fa-trash-alt text-right btn btn-tl--delete'></i>")
    $fieldRemoveButton.get(0).onclick = this.confirmFieldRemoval.bind(this, fieldName)

    return $fieldRemoveButton
  }

  confirmFieldRemoval (fieldName) {
    dataConfirmModal.confirm({
      title: 'Eliminar o campo',
      text: 'Tem a certeza?',
      commit: 'Eliminar',
      cancel: 'Cancelar',
      onConfirm: this.removeField.bind(this, fieldName)
    })
  }

  /* FIELD NAME */
  generateFieldName (fieldName) {
    const $inputField = $('<div class="input-field">')
    const $fieldNamelabel = $('<label><strong>Título do campo</strong></label>')
    const $fieldNameInput = $(`<input type="text" class="form-control" value="${fieldName}">`)
    $fieldNameInput.get(0).onblur = this.updateFieldName.bind(this, fieldName, $fieldNameInput)

    $inputField.append($fieldNamelabel, $fieldNameInput)

    return [$inputField]
  }

  /* FIELD TYPE */
  generateFieldType (fieldName) {
    const $inputField = $('<div class="input-field">')
    const $fieldTypeLabel = $('<label><strong>Tipo de campo</strong></label>')
    const $selectManager = $('<div class="select--manager dyn-content__select-field">')

    const $selectOptions = Object.entries(CREATOR.FIELD_TYPES)
      .map(textValuePair => $(`<option value="${textValuePair[1]}">${textValuePair[0]}</option>`))

    const $select = $('<select class="select"></select>')
    $selectManager.append($select)
    $select.append($selectOptions)
    $select.val(this.schema.properties[fieldName].field_type)
    $select.get(0).onchange = $event => this.updateFieldType($event, fieldName)

    $inputField.append($fieldTypeLabel, $selectManager)

    return [$inputField]
  }

  generateFieldText (fieldName) {
    const text = this.schema.properties[fieldName].text
    const $inputField = $('<div class="input-field">')
    const $textLabel = $('<label><strong>Texto</strong></label>')
    const $tinyMCEContainer = $('<div class="input-field__tinymce-container">')
    const $textInput = $(`<h1 selector=tinymce_${fieldName} class="tinymce form-control">${text}</h1>`)

    $tinyMCEContainer.append($textInput)
    $inputField.append($textLabel, $tinyMCEContainer)

    return [$inputField]
  }

  /* FIELD REQUIRED */
  generateFieldRequired (fieldName) {
    const $inputField = $('<div class="input-field">')
    const $fieldRequiredLabel = $('<label><strong>Obrigatório</strong></label>')
    const $fieldRequiredCheckbox = $(`<input type="checkbox" data-toggle="toggle" ${this.schema.required.includes(fieldName) ? 'checked' : ''}>`)

    $fieldRequiredCheckbox.get(0).onchange = this.updateFieldRequired.bind(this, fieldName, $fieldRequiredCheckbox)

    $inputField.append($fieldRequiredLabel, $fieldRequiredCheckbox)

    return [$inputField]
  }

  /* FIELD OPTIONS */
  generateFieldOptions (fieldName) {
    const $inputField = $('<div class="input-field">')
    const $fieldOptionsLabel = $('<label><strong>Opções</strong></label>')

    const $fieldOptionsList = $('<div class="options-grid">')

    this.getFieldEnum(fieldName).forEach((option) => {
      const $optionDiv = $('<div class="d-flex">')

      const $optionNameInput = $(`<input type="text" class="form-control" placeholder="Opção" value="${option}">`)
      $optionNameInput.get(0).onblur = this.updateSelectFieldOptionName.bind(this, fieldName, option, $optionNameInput)

      const $optionRemoveButton = $("<i class='fal fa-trash-alt my-auto ml-2'></i>")
      $optionRemoveButton.get(0).onclick = this.removeSelectFieldOption.bind(this, fieldName, option)

      $optionDiv.append($optionNameInput, $optionRemoveButton)
      $fieldOptionsList.append($optionDiv)
    })

    const $fieldAddOptionButton = $("<input type='button' class='btn-tl--main small_button mt-2' value='Adicionar opção'>")
    $fieldAddOptionButton.get(0).onmouseup = this.addSelectFieldOption.bind(this, fieldName)

    $inputField.append($fieldOptionsLabel, $fieldOptionsList)

    return [$inputField, $fieldAddOptionButton]
  }

  setupTinyMCE (fieldName) {
    const updateText = this.updateText.bind(this)

    tinymce.init({
      selector: '.tinymce',
      init_instance_callback: editor => {
        editor.on('blur', () => {
          updateText(fieldName, tinyMCE.activeEditor.getContent())
        })
      },
      menubar: false,
      inline: true,
      language: 'pt_PT',
      plugins: [
        'autolink link',
        'code',
        'insertdatetime paste code'
      ],
      toolbar: 'bold italic underline strikethrough | link'
    })
  }
}

/* CONSTANTS */
const FIELD_TYPE_SCHEMA_OBJECT = {
  [CREATOR.FIELD_TYPE_TEXT]: {
    type: 'string',
    field_type: CREATOR.FIELD_TYPE_TEXT
  },
  [CREATOR.FIELD_TYPE_SELECT]: {
    type: 'string',
    field_type: CREATOR.FIELD_TYPE_SELECT,
    enum: []
  },
  [CREATOR.FIELD_TYPE_SINGLE_CHECKBOX]: {
    type: 'string',
    field_type: CREATOR.FIELD_TYPE_SINGLE_CHECKBOX
  },
  [CREATOR.FIELD_TYPE_MULTIPLE_CHECKBOX]: {
    type: 'array',
    field_type: CREATOR.FIELD_TYPE_MULTIPLE_CHECKBOX,
    items: {
      enum: []
    }
  },
  [CREATOR.FIELD_TYPE_DATE]: {
    type: 'string',
    format: 'date',
    field_type: CREATOR.FIELD_TYPE_DATE
  },
  [CREATOR.FIELD_TYPE_RADIO]: {
    type: 'string',
    field_type: CREATOR.FIELD_TYPE_RADIO,
    enum: []
  },
  [CREATOR.FIELD_TYPE_INCREMENTABLE]: {
    type: 'string',
    field_type: CREATOR.FIELD_TYPE_INCREMENTABLE,
    pattern: '^[0-9]+$'
  },
  [CREATOR.FIELD_TYPE_LICENSE_PLATE]: {
    type: 'string',
    pattern: '^[A-Z0-9]{6}$',
    field_type: CREATOR.FIELD_TYPE_LICENSE_PLATE
  }
}
