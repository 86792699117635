import moment from 'moment'
import i18n from '../../initializers/i18n'
import { Controller } from 'stimulus'

export default class extends Controller {
  connect () {
    this.element.controller = this
    this.create()
  }

  reset () {
    this.create(true)
  }

  create (reset = false) {
    let startDate = this.startDate
    let endDate = this.endDate
    if (this.startEndDate) {
      [startDate, endDate] = this.startEndDate
    }
    if (reset) {
      startDate = this.resetStartDate
      endDate = this.resetEndDate
    }
    $(this.element).daterangepicker({
      locale: {
        format: this.format,
        cancelLabel: 'Restaurar datas',
        applyLabel: 'Aplicar',
        daysOfWeek: moment.localeData(i18n.locale).weekdaysShort()
      },
      autoApply: this.autoApply,
      minDate: this.minDate,
      startDate: startDate,
      endDate: endDate,
      showDropdowns: this.showDropdowns
    })

    // Set on cancel, to reset
    if (this.resetOnCancel) {
      $(this.element).on('cancel.daterangepicker', () => {
        this.reset()
      })
    }

    if (this.startEmpty && !reset) this.clear()

    if (this.blockDropdowns) {
      $(this.element).on('show.daterangepicker', (_ev, picker) => {
        $(picker.container).find('.month select').prop('disabled', true)
      })
    }
  }

  clear () {
    $(this.element).val('')
    $(this.element).trigger('change')
  }

  get format () {
    return this.data.get('format') || 'YYYY/MM/DD'
  }

  get minDate () {
    const minDate = this.data.get('minDate')
    return minDate ? new Date(minDate) : false
  }

  get startDate () {
    const startDate = this.data.get('startDate')
    return startDate ? moment(startDate, 'YYYY-MM-DD') : moment()
  }

  get endDate () {
    const endDate = this.data.get('endDate')
    return endDate ? moment(endDate, 'YYYY-MM-DD') : moment()
  }

  get startEndDate () {
    const startEndDate = this.data.get('startEndDate')
    if (startEndDate) {
      const [startDate, endDate] = startEndDate.split(' - ')
      return [moment(startDate, 'YYYY-MM-DD'), moment(endDate, 'YYYY-MM-DD')]
    } else {
      return null
    }
  }

  get resetStartDate () {
    const resetStartDate = this.data.get('resetStartDate')
    return resetStartDate ? new Date(resetStartDate) : moment()
  }

  get resetEndDate () {
    const resetEndDate = this.data.get('resetEndDate')
    return resetEndDate ? new Date(resetEndDate) : moment()
  }

  get startEmpty () {
    return this.data.get('startEmpty') === 'true'
  }

  get autoApply () {
    return this.data.get('autoApply') || true
  }

  get showDropdowns () {
    return this.data.get('showDropdowns') === 'true'
  }

  get blockDropdowns () {
    return this.data.get('blockDropdowns') === 'true'
  }

  get resetOnCancel () {
    return this.data.get('resetOnCancel') === 'true'
  }
}
