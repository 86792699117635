import { Controller } from 'stimulus'

export default class extends Controller {
  connect () {
    $(this.element).select2({
      placeholder: this.placeholder,
      ajax: this.ajax,
      allowClear: this.allowClear,
      tags: this.tags,
      multiple: this.multiple
    })
  }

  get placeholder () {
    return this.element.dataset.placeholder || $.fn.select2.defaults.defaults.placeholder
  }

  get ajax () {
    if (this.element.dataset.ajaxUrl) {
      return {
        url: this.element.dataset.ajaxUrl,
        dataType: this.element.dataset.ajaxDataType || 'json',
        delay: 250,
        data: params => Object.assign({}, params, JSON.parse(this.element.dataset.ajaxData || null))
      }
    } else {
      return null
    }
  }

  get allowClear () {
    return this.element.dataset.allowClear || $.fn.select2.defaults.defaults.allowClear
  }

  get tags () {
    return this.element.dataset.tags || $.fn.select2.defaults.defaults.tags
  }

  get multiple () {
    return this.element.dataset.multiple || $.fn.select2.defaults.defaults.multiple
  }
}
