import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    "bulkEventIds", "bulkSelectField", "eventAllCheckboxDesktop", "eventAllCheckboxMobile",
    "eventCheckbox", "eventToggler", "bulkSwitch",
    "modal", "modalList", "modalCount", "modalHiddenIds", "modalSubmit", "modalItemTemplate"
  ]
  static values = { isBulk: Boolean, bulkEvents: String, bulkEventsCount: Number }

  currIds = []
  currSelectedEventsCount = 0
  checkboxByEventId = {}
  checkboxByRootEventId = {}
  sessionsDatesByEventId = {}

  connect () {
    this.element.controller = this
    
    // Wait on events list loading
    $(document).on('events-list-loaded', () => {
      this.checkboxByRootEventId = {}
      this.setup()
    })

    // Show loading spinner on seeing events
    if (this.hasModalSubmitTarget){
      this.modalSubmitTarget.addEventListener('click', () => {
        $('.full-spinner').show()
      })
    }

    if (this.isBulkValue) {
      this.setupSubPagesModal()
    }

    this.setupModalListToggler()
  }

  setup () {
    if ((this.hasEventAllCheckboxDesktopTarget || this.hasEventAllCheckboxMobileTarget) && this.hasEventCheckboxTarget && this.hasBulkSwitchTarget) {
      // Set initial state from previous search
      this.bulkSwitchTarget.checked = this.bulkSelectFieldTarget.value === "true"
      this.changeCheckboxDisplay(this.bulkSwitchTarget.checked)

      // Setup up bulk switch change
      $(this.bulkSwitchTarget).on('click', () => {
        this.bulkSelectFieldTarget.value = this.bulkSwitchTarget.checked 

        // FIXME: Check if we want this behavior!
        // Mark previous/initial values in the checkboxes
        if (this.bulkSwitchTarget.checked) this.setCheckboxesValues()

        this.changeCheckboxDisplay(this.bulkSwitchTarget.checked)

        this.changePickerSessions(this.bulkSwitchTarget.checked ? this.currSelectedEventsCount : 0)
      })

      // Listen for click on the checkboxes for adding to the form filters
      for (let eventCheckboxTarget of this.eventCheckboxTargets) {
        // Only setup click for child event checkbox
        const rootEventId = eventCheckboxTarget.getAttribute('data-list-root-id')
        const eventId = eventCheckboxTarget.getAttribute('data-list-event-id')
        
        // Store checkboxes
        this.checkboxByEventId[eventId] = eventCheckboxTarget
        const hash = this.checkboxByRootEventId[rootEventId]
        if (!hash) hash = this.checkboxByRootEventId[rootEventId] = []
        if (rootEventId != eventId) hash.push(eventCheckboxTarget)

        if (rootEventId !== eventId)
          eventCheckboxTarget.addEventListener('click', (event) => {
            event.preventDefault()
            const checkbox = eventCheckboxTarget.querySelector('input')
            checkbox.checked = !checkbox.checked
            this.checkboxClick(eventCheckboxTarget)
          })
      }

      // Mark previous/initial values in the checkboxes
      if (this.bulkSwitchTarget.checked) this.setCheckboxesValues()
    }
  }

  setupSubPagesModal () {
    // Display modal 
    this.modalTarget.style.display = 'block'
    // Add counter to modal
    const pluralChar = (this.bulkEventsCountValue === 1 ? '' : 's')
    this.modalCountTarget.innerHTML = `${this.bulkEventsCountValue} evento${pluralChar} selecionado${pluralChar}`
    const bulkEvents = JSON.parse(this.bulkEventsValue.replaceAll('=>', ':'))
    const itemTemplate = this.modalItemTemplateTarget
    
    Object.entries(bulkEvents).forEach(([key, value]) => {  
      const newItem = itemTemplate.cloneNode(true)
      newItem.querySelector('[selector="event-name"]').innerHTML = key
      newItem.querySelector('[selector="event-date"]').innerHTML = value
      newItem.style.display = ""

      // Add new item to list
      this.modalListTarget.append(newItem)
    })
  }

  setupModalListToggler () {
    const controller = this
    const listToggler = $(this.modalTarget).find('[selector="list-toggler"]')
    this.modalTarget.querySelector('[selector="toggler-bar"]').addEventListener('click', function () {
      listToggler.toggleClass('down')
  
      const listEl = $(controller.modalTarget).find('[selector="selected-list"]')
      if (listEl.hasClass('dropdown__slide')) {
        if (listEl.hasClass('dropdown__slide--open')) {
          listEl.removeClass('dropdown__slide--open')
          listEl.removeClass('active')
          listEl.addClass('dropdown__slide--close')
          listEl.addClass('dropdown__slide--no-border')
        } else {
          listEl.removeClass('dropdown__slide--close')
          listEl.addClass('dropdown__slide--open')
          parent.addClass('active')
          listEl.removeClass('dropdown__slide--no-border')
        }
      } else {
        listEl.stop().slideToggle()
      }
    })
  }

  changeCheckboxDisplay(show = true) {
    const useLeafEvents = true
    // Check if we want to show event checkboxes
    if (!useLeafEvents) {
      for (let eventTogglerTarget of this.eventTogglerTargets) {
        // If the toggler is down, then the events are open, close them!
        if (show && eventTogglerTarget.querySelector(".rotate").classList.contains('down')) {
          eventTogglerTarget.click()
        }
        eventTogglerTarget.style.display = !show ? '' : 'none'
      }
    }

    // Display all events checkbox
    if (show) {
      this.eventAllCheckboxDesktopTarget.style.display = ''
      this.eventAllCheckboxMobileTarget.style.display = ''

      const clickFunction = (event, target) => {
        let checkbox = null
        if (target === "checkboxDesktop") {
          checkbox = this.eventAllCheckboxDesktopTarget.querySelector('input')
        } else if (target === "checkboxMobile") {
          checkbox = this.eventAllCheckboxMobileTarget.querySelector('input')
        }
        // Prevent default click action
        event.stopPropagation()
        event.preventDefault()

        if (checkbox) { 
          checkbox.checked = !checkbox.checked
          this.checkboxClick(checkbox.parentElement.parentElement)
        }
      }
      this.eventAllCheckboxDesktopTarget.addEventListener('click', ev => clickFunction(ev, "checkboxDesktop"))
      this.eventAllCheckboxDesktopTarget.clickFunctionDesktop = clickFunction

      this.eventAllCheckboxMobileTarget.addEventListener('click', ev => clickFunction(ev, "checkboxMobile"))
      this.eventAllCheckboxMobileTarget.clickFunctionMobile = clickFunction
    } else {
      // Checkbox desktop
      this.eventAllCheckboxDesktopTarget.style.display = 'none'
      const clickFunctionDesktop = this.eventAllCheckboxDesktopTarget.clickFunctionDesktop
      if (clickFunctionDesktop) this.eventAllCheckboxDesktopTarget.removeEventListener('click', clickFunctionDesktop, false)
      // Checkbox mobile
      this.eventAllCheckboxMobileTarget.style.display = 'none'
      const clickFunctionMobile = this.eventAllCheckboxMobileTarget.clickFunctionMobile
      if (clickFunctionMobile) this.eventAllCheckboxMobileTarget.removeEventListener('click', clickFunctionMobile, false)
    }

    for (let eventCheckboxTarget of this.eventCheckboxTargets) {
      // Change table layout
      if (useLeafEvents) {
        if (show) eventCheckboxTarget.parentNode.classList.add('table__unit--row--bulk')
        else eventCheckboxTarget.parentNode.classList.remove('table__unit--row--bulk')
      }

      eventCheckboxTarget.style.display = show ? '' : 'none'

      // Change behavior of click on table row
      let rowElement = $(eventCheckboxTarget).closest('a').get(0)
      if (!rowElement) rowElement = eventCheckboxTarget.parentElement.querySelector('a')

      if (rowElement && show) {
        const clickFunction = (event) => {
          const checkbox = eventCheckboxTarget.querySelector('input')
          event.stopPropagation()
          event.preventDefault()
          
          // Event.path is not standard (reference: https://stackoverflow.com/questions/39245488/event-path-is-undefined-running-in-firefox)
          let eventPath = event.path || (event.composedPath && event.composedPath())
          if (!eventPath) {
            eventPath = [event.target, event.target.parentElement]
          }
          
          // Check if it is the click on the box or on the toggler
          const hasToggler = eventPath.includes($(eventCheckboxTarget).siblings('.toggler').get(0))
          if (event.target == checkbox || hasToggler) {
            if (!hasToggler) checkbox.checked = !checkbox.checked

            return
          }
          
          checkbox.checked = !checkbox.checked
          this.checkboxClick(checkbox.parentElement.parentElement)

          // Check if it is a parent event and mark all children
          const rootEventId = eventCheckboxTarget.getAttribute('data-list-root-id')
          const eventId = eventCheckboxTarget.getAttribute('data-list-event-id')
          const shouldAllChildren = eventCheckboxTarget.getAttribute('data-list-has-all-children') != 'false'
          if (shouldAllChildren && rootEventId == eventId) {
            // Mark all children
            for (const childEventCheckbox of this.checkboxByRootEventId[rootEventId]) {
              childEventCheckbox.querySelector('input').checked = checkbox.checked
            }
          }
        }
        rowElement.addEventListener('click', clickFunction)
        rowElement.clickFunction = clickFunction
      } else if (rowElement) {
        const clickFunction = rowElement.clickFunction
        if (clickFunction) rowElement.removeEventListener('click', clickFunction, false)
      }
    }

    this.modalTarget.style.display = show ? '' : 'none'
  }

  setCheckboxesValues() {
    const chosenIds = {}
    // Store chosen ids in hash for quicker access
    JSON.parse(`[${this.bulkEventIdsTarget.value}]`).forEach(id => chosenIds[id] = true)
    const parentCheckboxById = {}
    const allIds = []
    const checkboxByEventId = {}
    this.eventCheckboxTargets.forEach(el => {
      const eventId = parseInt(el.querySelector('input')?.value)
      checkboxByEventId[eventId] = el
      if (el.getAttribute('data-list-is-parent') == 'true') parentCheckboxById[eventId] = el
      allIds.push(eventId)
    })
    allIds.forEach(id => {
      const corrEl = this.eventCheckboxTargets.find(el => el.querySelector('input')?.value?.toString() == id.toString())
      if (corrEl) {
        if (chosenIds[id]) corrEl && (corrEl.querySelector('input').checked = true)
        else corrEl && (corrEl.querySelector('input').checked = false)
      }
    })
    
    // Check if any parent should be indeterminate
    Object.keys(parentCheckboxById).forEach(rootEventId => {
      const el = parentCheckboxById[rootEventId]
      const isParentChecked = el.querySelector('input').checked == true
      const allAvailable = el.getAttribute('data-list-has-all-children') != 'false'
      const allAvailableSelected = !this.checkboxByRootEventId[rootEventId]
        .some(childEventCheckbox => !childEventCheckbox.querySelector('input').checked)
      const anyAvailableSelected = this.checkboxByRootEventId[rootEventId]
        .some(childEventCheckbox => childEventCheckbox.querySelector('input').checked)
      // Mark as indeterminate only if not all are available and at least a leaf is selected or all are available but
      //   not all are selected and at least one is
      if ( (!allAvailable && allAvailableSelected) || (allAvailable && !allAvailableSelected && anyAvailableSelected)) {
        const input = el.querySelector('input')
        input.checked = false
        input.indeterminate = true
        this.setCheckboxIndeterminate(el)
      // In case that the parent is selected with all children, we need to select the children
      } else if (allAvailable && isParentChecked) {
        this.checkboxByRootEventId[rootEventId].forEach(el => {
          el.querySelector('input').checked = true
        })
      }
    })

    // Check if all the events are selected and set alleventcheckbox
    this.setAllEventsCheckbox(checkboxByEventId)
  }

  checkboxClick(el) {
    const input = el.querySelector('input')
    const shouldGetRoot = el.getAttribute('data-list-has-all-children') != 'false'
    // input.checked = !input.checked
    let eventId = parseInt(input.value)
    const previousIds = JSON.parse(`[${this.bulkEventIdsTarget.value}]`)
    // If checked, add value to array
    let newIds = previousIds
    let added = false
    const rootEventId = parseInt(el.getAttribute('data-list-root-id') || '0')
    const rootEventCheckbox = this.eventCheckboxTargets
      .find(el => parseInt(el.querySelector('input')?.value) === rootEventId)
    const isRoot = rootEventId === eventId
    const isAllEvents = el.getAttribute('data-list-all-events') == "true"
    // If all events button was clicked
    if (isAllEvents) {
      let allEventChecked = el.querySelector('input').checked
      // let aux = []
      for (let eventCheckboxTarget of this.eventCheckboxTargets) {
        let rootId = parseInt(eventCheckboxTarget.getAttribute('data-list-root-id'))
        let eventIdTarget = parseInt(eventCheckboxTarget.querySelector('input').value)
        // If not root then skip for next iteration
        if (!(rootId == eventIdTarget)) continue

        let check = eventCheckboxTarget.querySelector('input').checked
        let indeterminate = eventCheckboxTarget.querySelector('input').indeterminate
        // Unclick all event checkbox
        if (!allEventChecked) {
          // If it is selected we remove
          // Check and indeterminate have different behaviours
          if (check) {
            eventCheckboxTarget.querySelector('input').checked = false
            this.checkboxClick(eventCheckboxTarget)
          } else if (indeterminate) {
            eventCheckboxTarget.querySelector('input').checked = true
            this.checkboxClick(eventCheckboxTarget)
          }
        // Click all event checkbox, is not already selected
        } else if (allEventChecked && !newIds.includes(rootId)) {
          // Prevent that we select already selected events
          if (!check && !indeterminate) {
            eventCheckboxTarget.querySelector('input').checked = true
            this.checkboxClick(eventCheckboxTarget)
          // Interminate has different behaviour
          } else if (indeterminate) {
            eventCheckboxTarget.querySelector('input').indeterminate = false
            this.setCheckboxIndeterminate(eventCheckboxTarget)
            eventCheckboxTarget.querySelector('input').checked = true
            this.checkboxClick(eventCheckboxTarget)
          }
        }

        if (eventCheckboxTarget.getAttribute('data-list-has-all-children') != 'false') {
          for (let childEventCheckbox of this.checkboxByRootEventId[rootId]) {
            childEventCheckbox.querySelector('input').checked = allEventChecked
          }
        }
      }

      newIds = JSON.parse(`[${this.bulkEventIdsTarget.value}]`)
    }
    // When the root is selected, do not add the children
    else if (!isRoot && input.checked && rootEventCheckbox?.querySelector('input')?.checked) {
      // Do not add if the root is added
      return
    }
    // It is root but indeterminate
    else if (isRoot && rootEventCheckbox?.querySelector('input')?.indeterminate) {
      this.checkboxByEventId[rootEventId].querySelector('input').checked = false
      this.checkboxByEventId[rootEventId].querySelector('input').indeterminate = false
      this.setCheckboxIndeterminate(this.checkboxByEventId[rootEventId])

      for (const childEventCheckbox of this.checkboxByRootEventId[rootEventId]) {
        const currEventId = parseInt(childEventCheckbox.getAttribute('data-list-event-id'))
        const idPos = newIds.indexOf(currEventId)
        if (currEventId !== eventId && idPos != -1) {
          newIds.splice(idPos, 1)
          this.currSelectedEventsCount -= 1
          childEventCheckbox.querySelector('input').checked = false
          this.updateListEvents(currEventId, false)
        }
      }
    }
    // When the root is selected, but we remove one of the children
    else if (!isRoot && !input.checked && rootEventCheckbox?.querySelector('input')?.checked) {
      // Remove root
      newIds.splice(newIds.indexOf(rootEventId), 1)
      this.updateListEvents(rootEventId, false)

      // Prepare all leafs
      for (const childEventCheckbox of this.checkboxByRootEventId[rootEventId]) {
        const currEventId = parseInt(childEventCheckbox.getAttribute('data-list-event-id'))
        newIds.push(currEventId)
        this.updateListEvents(currEventId, true)
      }
      // Mark the root as unchecked
      this.checkboxByEventId[rootEventId].querySelector('input').checked = false
      this.checkboxByEventId[rootEventId].querySelector('input').indeterminate = true
      this.setCheckboxIndeterminate(this.checkboxByEventId[rootEventId])

      // Remove curr leaf
      newIds.splice(newIds.indexOf(eventId), 1)
      this.currSelectedEventsCount -= 1
      added = false
    }
    // Added leaf/root
    else if (input.checked && newIds.indexOf(eventId) === -1) {
      let addCurrEventId = true
      // If it is a root, remove all leafs
      if (isRoot) {
        // Prepare all leafs
        for (const childEventCheckbox of this.checkboxByRootEventId[rootEventId]) {
          if (childEventCheckbox.querySelector('input')?.checked && shouldGetRoot) {
            const currEventId = parseInt(childEventCheckbox.getAttribute('data-list-event-id'))
            newIds.splice(newIds.indexOf(currEventId), 1)
            this.currSelectedEventsCount -= 1
            this.updateListEvents(currEventId, false)
          } else if (!shouldGetRoot && !childEventCheckbox.querySelector('input')?.checked) {
            const currEventId = parseInt(childEventCheckbox.getAttribute('data-list-event-id'))
            newIds.push(currEventId)
            this.currSelectedEventsCount += 1 
            this.currIds = newIds.filter((x, i, a) => a.indexOf(x) == i)
            childEventCheckbox.querySelector('input').checked = true
            this.updateListEvents(currEventId, true)
          }
        }
        // Uncheck input if all the leafs are not present
        if (!shouldGetRoot) {
          // Open root to make sure we can see the leafs
          const eventTogglerTarget = this.eventTogglerTargets
            .find((e) => e.getAttribute('event_id') == rootEventId.toString())
          if (!eventTogglerTarget.querySelector(".rotate").classList.contains('down')) {
            eventTogglerTarget.click()
          }
          addCurrEventId = false
          this.checkboxByEventId[rootEventId].querySelector('input').checked = false
          this.checkboxByEventId[rootEventId].querySelector('input').indeterminate = true
          this.setCheckboxIndeterminate(this.checkboxByEventId[rootEventId])
          this.currIds = newIds
          this.bulkEventIdsTarget.value = newIds
          return
        }
      }
      else if (!isRoot) {
        // Check if all leaves are selected to set only root
        const leafIds = this.checkboxByRootEventId[rootEventId]
          .map(elem => parseInt(elem.getAttribute('data-list-event-id')))
        const hasAllLeafs = leafIds.every(id => newIds.concat([eventId]).includes(id))
        // This will only occur if the aggregator has all children filtered
        if (hasAllLeafs && shouldGetRoot) {
          // Remove all leafs
          for (const childEventCheckbox of this.checkboxByRootEventId[rootEventId]) {
            const currEventId = parseInt(childEventCheckbox.getAttribute('data-list-event-id'))
            if (currEventId !== eventId) {
              newIds.splice(newIds.indexOf(currEventId), 1)
              this.currSelectedEventsCount -= 1
              this.updateListEvents(currEventId, false)
            }
          }
          // Mark root event as checked
          this.checkboxByEventId[rootEventId].querySelector('input').checked = true
          this.checkboxByEventId[rootEventId].querySelector('input').indeterminate = false
          this.setCheckboxIndeterminate(this.checkboxByEventId[rootEventId])

          eventId = rootEventId
        } else if ( (hasAllLeafs && !shouldGetRoot) ||
          (shouldGetRoot && !hasAllLeafs && leafIds.some(id => newIds.concat([eventId]).includes(id)))) {
          this.checkboxByEventId[rootEventId].querySelector('input').indeterminate = true
          this.checkboxByEventId[rootEventId].querySelector('input').checked = false
          this.setCheckboxIndeterminate(this.checkboxByEventId[rootEventId])
        }
      }

      if (addCurrEventId) newIds.push(eventId)
      added = true
    }
    // Else, remove from array
    else if (!input.checked && newIds.indexOf(eventId) != -1) {
      newIds.splice(newIds.indexOf(eventId), 1)
      if (isRoot && this.checkboxByRootEventId[eventId] !== undefined) {
        const eventLength = this.checkboxByRootEventId[eventId]?.length
        // If it is zero it means that the root does not have childs
        this.currSelectedEventsCount -= eventLength === 0 ? 1 : eventLength
      } else {
        this.currSelectedEventsCount -= 1
      }
      if (!isRoot &&
          !this.checkboxByRootEventId[rootEventId]
            .some(childEventCheckbox => childEventCheckbox.querySelector('input').checked)) {
        this.checkboxByEventId[rootEventId].querySelector('input').indeterminate = false
        this.checkboxByEventId[rootEventId].querySelector('input').checked = false
        this.setCheckboxIndeterminate(this.checkboxByEventId[rootEventId])
      }
    }

    newIds = newIds.filter((x, i, a) => a.indexOf(x) == i)
    this.currIds = newIds
    if ( newIds.includes(eventId) && this.checkboxByRootEventId[eventId] !== undefined && shouldGetRoot) {
      const eventLength = this.checkboxByRootEventId[eventId]?.length
      this.currSelectedEventsCount += eventLength === 0 ? 1 : eventLength
    } else if (newIds.includes(eventId) && this.checkboxByRootEventId[eventId] === undefined) {
      this.currSelectedEventsCount += 1
    }
    this.bulkEventIdsTarget.value = newIds
    this.updateListEvents(eventId, added)
    this.setAllEventsCheckbox(this.eventCheckboxTargets)
  }

  updateListEvents(changedId = undefined, added = undefined) {
    const eventsCount = this.currIds.length
    const pluralChar = (this.currSelectedEventsCount === 1 ? '' : 's')
    this.modalCountTarget.innerHTML = `${this.currSelectedEventsCount} evento${pluralChar} selecionado${pluralChar}`
    // Only allow bulk with 2 or more events
    this.modalTarget.querySelector('input[type="submit"]').disabled = eventsCount < 2

    this.modalHiddenIdsTarget.value = this.currIds
    
    // Add events chosen
    const itemTemplate = this.modalItemTemplateTarget
    if (changedId && added) {
      const eventListEl = document.querySelector(`.table__unit[data-list-event-id="${changedId}"]`)
      const newItem = itemTemplate.cloneNode(true)
      newItem.setAttribute('data-id', changedId)
      newItem.querySelector('[selector="event-name"]').innerHTML = eventListEl
        .querySelector('[data-event-name]').getAttribute('data-event-name')
      newItem.querySelector('[selector="event-date"]').innerHTML = eventListEl
        .querySelector('[data-event-date]').getAttribute('data-event-date')
      newItem.style.display = ""

      // Setup 
      const removeIcon = newItem.querySelector('[selector="remove-item"]')
      removeIcon.addEventListener('click', () => {
        const corrCheckbox = this.eventCheckboxTargets
          .find(el => el.querySelector('input')?.value?.toString() == changedId.toString())
        // If the the search contains the event clicked
        if (corrCheckbox) {
          corrCheckbox.click()
        }
        else {
          const previousIds = JSON.parse(`[${this.bulkEventIdsTarget.value}]`)
          let newIds = previousIds
          newIds.splice(newIds.indexOf(changedId), 1)
          this.currSelectedEventsCount -= 1
          this.currIds = newIds
          this.bulkEventIdsTarget.value = newIds
          this.updateListEvents(changedId, false)
        }
      })

      // Add new item to list
      this.modalListTarget.append(newItem)
    } else if (changedId && !added) {
      $(`[data-id='${changedId}']`).detach()
    }

    this.changePickerSessions(eventsCount)
  }

  setAllEventsCheckbox(data) {
    let selectedAllEvents = true
    Object.entries(data).forEach(([_id, el]) => {
      const input = el.querySelector('input')
      if (!input.checked && !input.indeterminate ) {
        selectedAllEvents = false
      }
    })
    if (selectedAllEvents) {
      this.eventAllCheckboxDesktopTarget.querySelector('input').checked = true
      this.eventAllCheckboxMobileTarget.querySelector('input').checked = true
    } else {
      this.eventAllCheckboxDesktopTarget.querySelector('input').checked = false
      this.eventAllCheckboxMobileTarget.querySelector('input').checked = false
    }
  }

  setCheckboxIndeterminate(checkbox) {
    const input = checkbox.querySelector('input')
    const checkboxSibling = input.nextElementSibling

    if (input.indeterminate) {
      checkboxSibling.classList.add('childrens-unchecked')
      if (!checkboxSibling.querySelector('.white-space')){
        const whitespace = document.createElement('div');
        whitespace.innerText = '—';
        whitespace.classList.add('white-space')
        checkboxSibling.append(whitespace)
      }
    } else {
      checkboxSibling.classList.remove('childrens-unchecked')
      checkboxSibling.querySelector('.white-space')?.remove()
    }
  }

  changePickerSessions (eventsCount) {
    const pickerController = document.querySelector('[select-controller="filter_start_end_date"]')?.controller
    if (!pickerController) return 
    if (eventsCount > 0) {
      // Populate with events sessions
      const parsedDates = this.currIds
        .flatMap((id) => this.sessionsDatesByEventId[id])
        .filter(date => !!date)
        .sort( (a, b) => new Date(a) - new Date(b))
      const newOptions = {
        minDate: parsedDates[0],
        maxDate: parsedDates.slice(-1)[0],
        filter: (d) => {
          const currDate = d.format('YYYY-MM-DD')
          return !parsedDates.includes(currDate)
        }
      }
      pickerController.setDates(newOptions.minDate, newOptions.maxDate)
      pickerController.changeLockOptions(newOptions)
      pickerController.resetStartDate = pickerController.constructorDatePicker(newOptions.minDate)
      pickerController.resetEndDate = pickerController.constructorDatePicker(newOptions.maxDate)
    } else {
      // Go back to initial info
      pickerController.changeLockOptions(pickerController.lockPluginOptions)
      pickerController.resetStartDate = pickerController.currResetStartDate
      pickerController.resetEndDate = pickerController.currResetEndDate
      pickerController.reset()
    }
  }
}
