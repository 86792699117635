
class ReimportTicketsService {
  progressInterval = undefined
  progressElement = undefined
  progressTimeInterval = 10000 // in ms
  iterations = 0

  setup (progressElement) {
    this.progressElement = progressElement
    // this.setupInterval()

    $('[selector="progress-update-button"]').on('click', () => {
      // Already updating, stop update!
      if (this.progressInterval) {
        clearInterval(this.progressInterval)
        this.progressInterval = undefined
      } else {
        this.setupInterval()
      }
      this.updateButton()
    })
  }

  setupInterval () {
    this.progressElement.innerHTML = "Iniciando..."
    this.progressInterval = setInterval(() => {
      this.iterations += 1
      $.ajax({ url: this.getURL() }).done(data => {
        // If there is no data or enough iterations, reset
        if (!data || this.iterations > 50) {
          this.iterations = 0
          this.clearInterval(this.progressInterval)
          this.progressInterval = undefined
          this.updateButton()
          return
        }
        const percentage = data.percentage
        this.progressElement.innerHTML = (data.percentage * 100).toFixed(2) + " %"

        if (percentage === 1) {
          clearInterval(this.progressInterval)
          this.progressInterval = undefined
          this.updateButton()
        }
      })
    }, this.progressTimeInterval)
    this.updateButton()
  }

  updateButton () {
    if (this.progressInterval) {
      $('[selector="progress-update-button"]').find('#title').text('Parar Atualização')
    } else {
      $('[selector="progress-update-button"]').find('#title').text('Atualizar')
    }
  }

  getURL () {
    return this.progressElement.dataset.href
  }
}

export default ReimportTicketsService
