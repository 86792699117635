import Base from '../../../base'
import Renderer from '../../../../lib/dynamic_forms/renderer'
import Creator from '../../../../lib/dynamic_forms/creator'

class Form extends Base {
  setup () {
    $('#dynamic_form_event_id').on('select2:selecting', e => {
      const selectedOption = e.params.args.data.id
      $('#dynamic_form_location_id').get(0).dataset.ajaxData = JSON.stringify({ event_id: selectedOption })
    })

    const renderer = new Renderer('#preview', { previewMode: true })
    new Creator('#dynamic_form', renderer).setup()
  }
}

export default Form
