$(document).on('ready', () => {
  if ($('#cart-index').length !== 0) {
    new SlickSlider('#event_suggestions', { to_show: 4 })

    $('[selector="close_insurance_modal"]').on('click', ev => {
      const $modal = $(ev.target).parents('.insurance-overlay')
      $modal.css('display', 'none')
    })
  }
})
