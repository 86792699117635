import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    totalPrice: Number,
    totalTickets: Number
  }

  static targets = ["totalPrice", "totalTickets", "confirmButton"]

  initialize(){
    this.totalPriceValue = 0
    this.totalTicketsValue = 0
  }

  // ACTIONS
  add(event){
    const $parent = $(event.currentTarget).parent()
    const $input = $parent.find("[selector='price']")
    const $counter = $parent.find("[selector='counter']")
    const $decrement = $parent.find("[selector='decrement']")
    const $availability = $parent.find("[selector='availability']")
    
    if ($availability.data('available') == true){

      const newValue = parseInt($input.val()) + 1
      $input.val(newValue)
      $counter.text(newValue)
      this.totalTicketsValue += 1
      this.totalPriceValue += parseFloat($input.data('price'))
      $decrement.removeClass('bg-light-grey').addClass('bg-dark-grey')
      
      this.updateTotal()
    }
  }

  remove(event){
    const $parent = $(event.currentTarget).parent()
    const $input = $parent.find("[selector='price']")
    const $counter = $parent.find("[selector='counter']")
    const $decrement = $parent.find("[selector='decrement']")

    let newValue = parseInt($input.val()) - 1
    if (newValue < 0 ){
      newValue = 0
    } else {
      this.totalTicketsValue -= 1
      this.totalPriceValue -= parseFloat($input.data('price'))
      this.updateTotal()
    }
    if(newValue == 0){
      $decrement.removeClass('bg-dark-grey').addClass('bg-light-grey')
    }
    $input.val(newValue)
    $counter.text(newValue)
  }

  updateTotal() {
    const $button = $(this.confirmButtonTarget)
    this.totalTicketsTarget.innerText = this.totalTicketsValue
    this.totalPriceTarget.innerText = this.totalPriceValue.toFixed(2).replace('.', ',')

    if (this.totalTicketsTarget.innerText > 0) {
      $button.css({'background-color' : '#bd161e', 'border-color' : '#bd161e'})
      $button.prop('disabled', false)
    } else {
      $button.css({'background-color' : '#9B9B9B', 'border-color' : '#9B9B9B'})
      $button.prop('disabled', true)
    }
  }
}