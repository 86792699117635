
$(document).on('ready', () => {
  if ($('#managers-events-event-sale-summary').length !== 0) {
    // Setup change to avoid refreshing when the granularity is the same
    let prevGranularityVal = $('.select#granularity_selector').val()
    $('.select#granularity_selector').on('change', function () {
      const currVal = $('.select#granularity_selector').val()

      // Only if different, update form
      if (prevGranularityVal !== currVal) {
        this.form.submit()
      }

      prevGranularityVal = currVal
    })
  }
})
