import { Renderer } from '../../../../lib/dynamic_forms'

$(document).on('ready', () => {
  if ($('#purchases-data, #purchases-data-processing').length !== 0) {
    $('[selector="dynamic_form"]').each((_index, $dynamicForm) => (new Renderer($dynamicForm)).render())

    const $countrySelects = $('#purchases_data_form_delivery_country_id, #purchases_data_form_billing_country_id')
    $countrySelects.on('change', ev => {
      const districtSelectEl = document.getElementById(ev.currentTarget.id.replace(/country/, 'district'))
      const districtSelectWrapperElement = districtSelectEl.parentElement

      if (ev.target.selectedOptions[0].text === 'Portugal') {
        districtSelectWrapperElement.style.display = 'block'
      } else {
        districtSelectWrapperElement.style.display = 'none'
        districtSelectEl.value = -1
        districtSelectEl.dispatchEvent(new Event('change'))
      }
    })
    $countrySelects.trigger('change')

    $('#copy_delivery_data').on('click', () => {
      const deliveryFields = ['name', 'address', 'phone', 'postal_code', 'nif', 'email']
      deliveryFields.forEach(field => {
        const deliveryValue = $('#purchases_data_form_delivery_' + field).val()
        $('#purchases_data_form_billing_' + field).val(deliveryValue)
        const errorMessage = $('#error-msg-purchases_data_form_billing_' + field)

        if (errorMessage !== '') {
          errorMessage.text('')
          $('#purchases_data_form_billing_' + field).removeClass('is-invalid')
        }
      })

      const selectDeliveryFields = ['district_id', 'country_id']
      selectDeliveryFields.forEach(field => {
        const deliveryValue = $('#purchases_data_form_delivery_' + field).val()
        $('#purchases_data_form_billing_' + field).val(deliveryValue)
        const deliveryText = $('#purchases_data_form_billing_' + field).get(0).selectedOptions[0].text
        $('#purchases_data_form_billing_' + field).parent().find('.styled_select').text(deliveryText)

        const errorMessage = $('#error-msg-purchases_data_form_billing_' + field)
        if (errorMessage !== '') {
          errorMessage.text('')
          $('#purchases_data_form_billing_' + field).removeClass('is-invalid')
        }
      })
    })
  }
})
